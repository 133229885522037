import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pt-1 ion-padding-horizontal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_ctx.show && _ctx.msg)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_text, { color: "danger" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.show && _ctx.msg ? _ctx.msg : ""), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}