
import { defineComponent } from "vue";
import { IonButton, IonIcon, } from "@ionic/vue";
import { helpCircleOutline } from "ionicons/icons";
import { UserDetails } from "@/models/userDetails";

export default defineComponent({
  name: "HelpButton",
  components: {
    IonButton,
    IonIcon,
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
  },
  setup() {
    return { helpCircleOutline };
  },
});
