
import { defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'SplashPlaceholder',
  props: {
    category: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  setup(props) {
    let cat;
    switch(props.category) {
      case 'Biological': cat = ref('bio-circle'); break;
      case 'Psychological': cat = ref('psych-circle'); break;
      case 'Social': cat = ref('social-circle'); break;
      case 'Spiritual': cat = ref('spiritual-circle'); break;
      default: cat = ref('circle-logo'); break;
    }
    return { cat }
  },
  watch: {
    category(val) {
      switch(this.category) {
      case 'Biological': this.cat = 'bio-circle'; break;
      case 'Psychological': this.cat = 'psych-circle'; break;
      case 'Social': this.cat = 'social-circle'; break;
      case 'Spiritual': this.cat = 'spiritual-circle'; break;
      default: this.cat = 'circle-logo'; break;
    }
    }
  }
})
