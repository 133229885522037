/*
Not really a plugin but meh.
vue-snack With some custom global configuration
*/
export const snackBarConfig = {
    time: 3000,
    close: true,
    font: {
     family: 'Montserrat, sans-serif',
      size: '14px',
    }
  };
  