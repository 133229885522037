import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    alt: "yrp-logo",
    src: "/../assets/logo.png",
    height: _ctx.height,
    width: _ctx.width,
    class: "flex auto align-self-center",
    contain: "",
    style: {"object-fit":"contain"}
  }, null, 8, _hoisted_1))
}