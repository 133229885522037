
import { defineComponent } from "vue";
import CircleProgress from "vue3-circle-progress";
export default defineComponent({
  name: "SingleCircle",
  components: {
    CircleProgress,
  },
  props: {
    color: {
      type: String,
      default: "1.0",
    },
    scale: {
      type: String,
      default: "1.0",
    },
    size: {
      type: Number,
      default: 180,
    },
    one: {
      type: Number,
      default: 0,
    },
  },
});
