import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "headline-5" }
const _hoisted_2 = {
  key: 0,
  class: "w100 flex"
}
const _hoisted_3 = { class: "auto" }
const _hoisted_4 = { class: "headline-5" }
const _hoisted_5 = { class: "auto" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_region_selector = _resolveComponent("region-selector")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_contact_list_item = _resolveComponent("contact-list-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_close_button, { onClick: _ctx.closeAndDismiss }, null, 8, ["onClick"]),
      _createVNode(_component_ion_grid, {
        class: "px-4",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-justify-content-center" }),
          _createVNode(_component_ion_row, { class: "ion-text-start px-4 pt-4 mt-2" }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("help.rec")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "pa-4" }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t("help.highlight1")) + " ", 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.lowestCategory.category), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("help.highlight2")), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                "size-lg": "12",
                "size-md": "12",
                size: "12"
              }, {
                default: _withCtx(() => [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_ion_spinner, {
                          style: {"transform":"scale(3)","margin":"24px auto","padding-top":"96px"},
                          class: "auto",
                          color: "primary",
                          name: "crescent"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.myRegion && !_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_ion_list, {
                        key: 1,
                        class: "w100"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            class: "flex w100"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t("help.selectRegion")), 1)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            class: "flex w100"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_region_selector)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.loading && _ctx.contacts && _ctx.myRegion && _ctx.contacts.length == 0)
                    ? (_openBlock(), _createBlock(_component_ion_list, {
                        key: 2,
                        class: "w100"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            class: "w100",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { class: "ion-text-wrap ion-text-center w100" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("help.sorry")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => {
                _ctx.$router.push('/home/contacts');
                _ctx.closeAndDismiss();
              }),
                                color: "primary",
                                class: "my-pointer no-corners auto"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(_ctx.$t("help.findOther")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.contacts && _ctx.contacts.length > 0 && _ctx.myRegion && !_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 3 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact) => {
                            return (_openBlock(), _createBlock(_component_contact_list_item, {
                              detail: "",
                              contact: contact,
                              onGoto: ($event: any) => {_ctx.close(); _ctx.$router.push('/home/contacts/' + contact.id);},
                              key: contact.id + '-contact',
                              class: "ma-2"
                            }, null, 8, ["contact", "onGoto"]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "pa-4 ma-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: "12",
                class: "ion-text-center"
              }, {
                default: _withCtx(() => [
                  (_ctx.lowestCategory && !_ctx.loading)
                    ? (_openBlock(), _createElementBlock("h4", _hoisted_6, [
                        _createElementVNode("a", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$router.push(`/home/contacts?search=${_ctx.lowestCategory.category}`); _ctx.close()})
                        }, " Looking for other " + _toDisplayString(_ctx.lowestCategory.category) + " contacts?", 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}