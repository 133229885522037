
import {
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { ContentService } from "@/services/strapi.service";
import CloseButton from './../common/CloseButton.vue'

export default defineComponent({
  name: "DistressModal",
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    CloseButton
  },
  setup() {
    const loading = ref(true);
    const data = ref(null as any);
    return { loading, data };
  },
  mounted() {
    // load emergency info
    this.loadData();
  },
  methods: {
    close() {
      modalController.dismiss();
    },
    async loadData() {
      const resp = await ContentService.getMany<any>('emergency-infos');
      if (resp && resp.length) {
        this.data = resp[0]
      }
    },
  },
});
