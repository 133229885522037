
import { defineComponent, ref } from "vue";
import { IonAvatar, IonButton, IonIcon, IonList, IonItem, IonLabel } from "@ionic/vue";
import { documentOutline, openOutline, thumbsDownOutline } from "ionicons/icons";
import { Resource } from "@/models/resource";
export default defineComponent({
  name: "ResourcesFeed",
  components: {
    IonAvatar,
    IonButton,
    IonList,
    IonIcon,
    IonItem,
    IonLabel,
  },
  computed: {
    resources(): Resource[] {
      return this.$store.state.resource.resources;
    },
  },
  async mounted() {
    this.$timer(2000);
    this.loading = false;
  },
  setup() {
    const loading = ref(true);
    return {loading, documentOutline,  openOutline, thumbsDownOutline}
  }
});
