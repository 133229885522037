
import {
  IonCol,
  IonGrid,
  IonRow,
  IonProgressBar,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import WellnessCircle from "@/components/common/WellnessCircle.vue";
import { CheckInForm } from "@/models/checkInForm";
export default defineComponent({
  name: "WizardForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonProgressBar,
    WellnessCircle,
  },
  computed: {
    checkInForm(): CheckInForm {
      return this.$store.state.check.checkInForm;
    },
    bioPercent(): number {
      return this.$store.getters["check/bioPercent"];
    },
    psychPercent(): number {
      return this.$store.getters["check/psychPercent"];
    },
    socialPercent(): number {
      return this.$store.getters["check/socialPercent"];
    },
    spiritPercent(): number {
      return this.$store.getters["check/spiritPercent"];
    },
  },
});
