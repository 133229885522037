
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonProgressBar,
  IonSpinner,
  IonSearchbar,
  IonSkeletonText,
  IonThumbnail,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  isPlatform,
} from "@ionic/vue";
import { personCircleOutline, chevronDown, chevronUp } from "ionicons/icons";
import { UserDetails } from "@/models/userDetails";
import { dateMixin } from "@/mixins/date.format";
import { colorMixin } from "@/mixins/get.color";
import { useSnackbarPlugin } from "snackbar-vue";
import ContactGoogleMap from "@/components/common/ContactGoogleMap.vue";
import { Contact } from "@/models/contact";
import { Region } from "@/models/region";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import { Geolocation } from "@capacitor/geolocation";
import HelpButton from "@/components/common/HelpButton.vue";
import { Keyboard } from "@capacitor/keyboard";
import ContactListItem from "@/components/home/contacts/ContactListItem.vue";
import HighlightContactItem from "@/components/home/contacts/HighlightContactItem.vue";

export default defineComponent({
  name: "ContactsListPage",
  mixins: [dateMixin, colorMixin],
  components: {
    BackButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonChip,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonList,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonProgressBar,
    IonSpinner,
    IonSearchbar,
    IonSkeletonText,
    IonThumbnail,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ContactGoogleMap,
    NotificationBell,
    HelpButton,
    ContactListItem,
    HighlightContactItem,
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    contacts(): Contact[] {
      return this.$store.state.contact.contacts;
    },
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    },
  },
  setup() {
    const loading = ref(true);
    const loading2 = ref(false);
    const content = ref();
    const centerPoint = ref({ lat: 43.86681, lng: -79.7252042 });
    const highlightContact = ref(null as any);
    const markers = ref([] as any);
    const snack = useSnackbarPlugin();
    const zoom = ref(10);
    const selectedRegion = ref(null as any);
    const search = ref("");
    const mocks = [1, 2, 3, 4, 5, 6];
    const apiOrSearch = ref("api"); // load from api or search
    const searchIsFocused = ref(false);
    const hideResults = ref(true);
    const isBrowser = isPlatform("desktop");
    return {
      content,
      mocks,
      loading,
      loading2,
      snack,
      centerPoint,
      markers,
      highlightContact,
      zoom,
      selectedRegion,
      search,
      personCircleOutline,
      apiOrSearch,
      isBrowser,
      searchIsFocused,
      hideResults,
      chevronDown,
      chevronUp,
    };
  },
  async ionViewWillEnter() {
    if (this.$route.query && this.$route.query.search) {
      this.search = this.$route.query.search.toString();
      this.filterBySearch();
    } else {
      await this.$timer(3000);
      if (this.myRegionId == null || this.myRegionId == undefined) {
        await this.setLocation();
      }
      this.filterByRegion(this.isBrowser ? this.myRegion : undefined);
    }
  },
  methods: {
    setHighlight(mark: {
      realPosition: { lat: number; lng: number };
      contact?: Contact;
    }) {
      if (this.contacts && this.contacts.length) {
        const x = this.contacts.find(
          (c) =>
            c.lat == mark.realPosition.lat && c.lng == mark.realPosition.lng
        );
        if (x) {
          this.highlightContact = x;
        }
      }
      // if list no longer contains the mark, we can still see it.
      if (mark.contact) {
        this.highlightContact = mark.contact;
      }
      return null;
    },
    setCenterPoint(lat: number, lng: number) {
      this.centerPoint = { lat, lng };
    },
    goToMarker(mark: { realPosition: { lat: number; lng: number } }) {
      if (mark) {
        this.zoom = 15; // zoom in!
        // the 0.006 offset allows for the center point to go below the highlight box.
        this.setCenterPoint(
          this.innerWidth < 768
            ? mark.realPosition.lat + 0.006
            : mark.realPosition.lat,
          mark.realPosition.lng
        );
      }
    },
    setMarkers(setCenter?: boolean) {
      if (this.contacts && this.contacts.length) {
        if (setCenter) {
          this.setCenterPoint(
            this.contacts[0].lat ?? 0,
            this.contacts[0].lng ?? 0
          );
        }
        this.contacts.forEach((c: Contact, index: number) => {
          // no exact match so adding pin, however is there another pin like it?
          if (-1 == this.markers.findIndex((m: any) => m.id == c.id)) {
            let count = 0;
            this.markers.forEach((m: any) => {
              if (
                (c.lat ?? 0) + (c.lng ?? 0) ==
                m.realPosition.lat + m.realPosition.lng
              ) {
                count++;
              }
            });
            let newPos = { lat: c.lat, lng: c.lng };

            if (count > 0) {
              const a = 360.0 / 3;
              const newLat =
                (c.lat ?? 0) +
                -0.00004 * Math.cos(((+a * count) / 180) * Math.PI); //x
              const newLng =
                (c.lng ?? 0) +
                -0.00004 * Math.sin(((+a * count) / 180) * Math.PI); //Y
              newPos = { lat: newLat, lng: newLng };
            }
            this.markers.push({
              realPosition: { lat: c.lat, lng: c.lng },
              position: newPos,
              label: c.name,
              title: c.name,
              icon: {
                url: this.getPin(c.category ? c.category.name ?? "" : ""),
                pointX: 24,
                pointY: 56 + 24 * count,
              },
              contact: c, // tricky :)
              id: c.id,
            });
          }
        });
      }
      // if no contacts, don't bother clearing pins
    },
    async setLocation() {
      try {
        const position = await Geolocation.getCurrentPosition();
        if (position) {
          this.setPosition(position);
        } else {
          throw Error();
        }
      } catch (err) {
        // if no region selected or declined,
        this.$store.dispatch("user/saveToStorage", {
          key: "myRegionId",
          value: 0,
        });
        this.snack.show({
          position: "bottom",
          text: "Geolocation is not available.",
          time: 3000,
          close: true,
        });
      }
    },
    setPosition(position: any) {
      if (position) {
        this.centerPoint = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.findClosestRegion(this.centerPoint);
      }
    },
    findClosestRegion(point: { lat: number; lng: number }) {
      // d = √[(x2 − x1)2 + (y2 − y1)2]
      let distanceBetween = 0;
      let cR = null as any; // closestRegion
      this.regions.forEach((r: any) => {
        if (!cR) {
          cR = r; // first one.
          distanceBetween = Math.sqrt(
            Math.pow(point.lat - r.lat, 2) + Math.pow(point.lng - r.lng, 2)
          );
        } else {
          const d = Math.sqrt(
            Math.pow(cR.lat - r.lat, 2) + Math.pow(cR.lng - r.lng, 2)
          );
          if (d < distanceBetween) {
            distanceBetween = d;
            cR = r;
          }
        }
      });
      if (cR) {
        // save region.
        this.$store.dispatch("user/saveToStorage", {
          key: "myRegionId",
          value: cR.id,
        });
      }
    },
    async filterByRegion(region?: Region, more?: boolean) {
      this.loading = true;
      this.apiOrSearch = "api";
      // this will load from api for all sorts of cases
      // desktop users may click a region, mobile its a free for all.

      // its a new region selection so lets do stuff, (not on a more request though)

      if (this.innerWidth < 768) {
        this.selectedRegion = null; // no selected region on mobile
      } else {
        if (
          !region ||
          (this.selectedRegion &&
            !more &&
            region.name == this.selectedRegion.name)
        ) {
          this.selectedRegion = null;
        } else {
          this.selectedRegion = region;
          this.setCenterPoint(region.lat ?? 0, region.lng ?? 0);
        }
      }

      await this.$store.dispatch(
        `contact/${more ? "getMoreContacts" : "getContacts"}`,
        this.selectedRegion ? { "region.name": this.selectedRegion.name } : {}
      );

      this.highlightContact = null;
      this.setMarkers(region ? false : true);
      this.loading = false;
      this.search = ""; // clear search too.
      this.zoom = 10; // zoom out!
    },
    async filterBySearch(more?: boolean) {
      this.hideResults = false; // only mobile.
      this.loading = true;
      this.loading2 = true;
      this.apiOrSearch = "search";
      await this.$store.dispatch(
        `contact/${more ? "getMoreSearchContacts" : "getSearchContacts"}`,
        this.search
      );
      this.selectedRegion = null;
      this.highlightContact = null;
      this.setMarkers(true);
      this.loading = false;
      this.loading2 = false;
    },
    getPin(category: string) {
      switch (category.toLowerCase()) {
        case "biological":
          return "/../assets/pin_bio.png";
        case "psychological":
          return "/../assets/pin_psych.png";
        case "social":
          return "/../assets/pin_social.png";
        case "spiritual":
          return "/../assets/pin_spirit.png";
        default:
          return "/../assets/pin_psych.png";
      }
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
    closeMe() {
      // close keyboard, force it
      if (!this.isBrowser) {
        Keyboard.hide();
      }
    },
    loadMore($ev: any) {
      if (this.apiOrSearch == "search") {
        this.filterBySearch(true);
      } else if (this.apiOrSearch == "api") {
        this.filterByRegion(this.selectedRegion, true);
      }
      if ($ev) {
        $ev.target.complete();
      }
    },
  },
});
