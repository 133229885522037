import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline-5" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_close_button, { onClick: _ctx.close }, null, 8, ["onClick"]),
      _createVNode(_component_ion_grid, { class: "px-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-justify-content-center" }),
          _createVNode(_component_ion_row, { class: "ion-text-start px-4" }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("menu.help")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "pa-4" }, {
            default: _withCtx(() => [
              (_ctx.data)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    "size-lg": "12",
                    "size-md": "12",
                    size: "12"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.data.information)
                        ? (_openBlock(), _createElementBlock("pre", {
                            key: 0,
                            class: "px-2",
                            innerHTML: _ctx.$marked(_ctx.data.information)
                          }, null, 8, _hoisted_2))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}