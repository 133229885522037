import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    fill: "none",
    class: "my-pointer",
    style: {"position":"absolute","top":"4px","right":"0px","z-index":"2"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_ripple_effect),
      _createVNode(_component_ion_icon, {
        button: "",
        icon: _ctx.closeOutline,
        size: "large"
      }, null, 8, ["icon"])
    ]),
    _: 1
  }))
}