
import {
  IonCol,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonPage,
  IonRow,
  IonRefresherContent,
  IonRefresher,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import SingleCircle from "@/components/common/SingleCircle.vue";
import SkeletonFeed from "@/components/common/SkeletonFeed.vue";
import TrendBlock from "@/components/home/dashboard/TrendBlock.vue";
import ArticlesFeed from "@/components/home/dashboard/ArticlesFeed.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
// import HelpButton from "@/components/common/HelpButton.vue";
import InfoButton from "@/components/common/InfoButton.vue";
import FavModalButton from "@/components/common/FavModalButton.vue";
import SidebarBox from "@/components/common/SidebarBox.vue";
import UpcomingEventsBox from "@/components/common/UpcomingEventsBox.vue";

export default defineComponent({
  name: "BioPage",
  components: {
    BackButton,
    IonCol,
    IonContent,
    IonGrid,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonPage,
    IonRow,
    IonRefresherContent,
    IonRefresher,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ArticlesFeed,
    SkeletonFeed,
    SingleCircle,
    TrendBlock,
    ResourcesInArea,
    OtherArticlesBox,
    NotificationBell,
    // HelpButton,
    InfoButton,
    FavModalButton,
    SidebarBox,
    UpcomingEventsBox,
  },
  setup() {
    const loading = ref(true);
    const category = ref("");
    const content = ref();
    const title = ref("");
    const color = ref("");
    const hex = ref("");
    return { category, content, color, hex, title, loading };
  },
  methods: {
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
    loadData(ev?: any, more?: boolean) {
      if (this.title) {
      this.$store.dispatch(
            `article/${more ? "getMoreArticles" : "getArticles"}`,
            { 'category.name': this.title }
          );
      }
      if (ev) {
        ev.target!.complete();
      }
    },
  },
  computed: {
    latestCheckIn() {
      return this.$store.getters["check/latestCheckIn"];
    },
    getQuestions(): any {
      switch (this.category) {
        case "biological":
          return this.$store.getters["question/bioQuestions"];
        case "psychological":
          return this.$store.getters["question/psychQuestions"];
        case "social":
          return this.$store.getters["question/socialQuestions"];
        case "spiritual":
          return this.$store.getters["question/spiritQuestions"];
        default:
          return [];
      }
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    },
  },
  async ionViewDidEnter() {
    switch (this.$route.path) {
      case "/home/bio":
        this.title = "Biological";
        this.category = "biological";
        this.color = "153, 202, 60";
        this.hex = "#99ca3c";
        break;
      case "/home/psych":
        this.title = "Psychological";
        this.category = "psychological";
        this.color = "88, 196, 235";
        this.hex = "#58c4eb";
        break;
      case "/home/social":
        this.title = "Social";
        this.category = "social";
        this.color = "244, 123, 75";
        this.hex = "#f47b4b";
        break;
      case "/home/spirit":
        this.title = "Spiritual";
        this.category = "spiritual";
        this.color = "154, 87, 163";
        this.hex = "#9a57a3";
        break;
      default:
        break;
    }
    await this.$timer(1000);
    this.loadData();
    this.loading = false;
    this.$store.dispatch("article/loadFavAsOtherArticles", this.title);
  },
});
