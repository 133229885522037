
import { Region } from "@/models/region";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegionPopover",
  components: { IonContent, IonList, IonItem, IonLabel },
  computed: {
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
  },
  methods: {
    selectRegion(region: Region) {
      this.$store.dispatch("user/saveToStorage", {
        key: "myRegionId",
        value: region.id,
      });
      this.$store.dispatch(
        "contact/getContacts",// TODO should this be filtered like resourcesInArea
        {'region.name' : region.name}
      );
      popoverController.dismiss();
    },
  },
});
