
import { defineComponent } from "vue";
import { IonCard } from "@ionic/vue";
import { QuestionResponse } from "@/models/questionResponse";
export default defineComponent({
  name: "TrendBlock",
  components: {
    IonCard,
  },
  props: {
    label: {
      type: String,
      default: "bio",
    },
    detail: {
      type: Boolean,
      default: false,
    },
    category: {
      default: null as any,
    },
    color: {
      type: String,
      default: "#58c4eb",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
  computed: {
    questionResponses(): QuestionResponse[] {
      return this.$store.state.check.questionResponses;
    },
    trends(): any[] {
      if (this.detail) {
        return this.questionResponses
          .filter((r) => r.questionId == this.category)
          .map((r) => r.response);
      } else {
        if (this.$store.state.check.myCheckIns.length == 0) return [];
        return this.$store.state.check.myCheckIns
          .map((c: any) => {
            return c[this.category];
          })
          .reverse();
      }
    },
    changeInTrend(): number {
      const l = this.trends.length;
      if (l == 0 || l < 2 || this.trends == null) return 0;

      let total = 0;
      this.trends.forEach((t: number, index: number) => {
        if (index > 0) {
          total = total + t;
        }
      });
      return this.trends[0] - Math.round(total / (this.trends.length - 1)); // ideal 6 or less.
    },
    absChangeInTrend(): number {
      return Math.abs(this.changeInTrend);
    },
  },
});
