import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pl-2" }
const _hoisted_2 = { class: "headline-5 w100 mt-2 mb-1" }
const _hoisted_3 = {
  key: 0,
  style: {"margin":"0"}
}
const _hoisted_4 = {
  key: 0,
  class: "auto py-4 px-2 flex"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_button = _resolveComponent("back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_notification_bell = _resolveComponent("notification-bell")!
  const _component_help_button = _resolveComponent("help-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_splash_placeholder = _resolveComponent("splash-placeholder")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_resources_in_area = _resolveComponent("resources-in-area")!
  const _component_other_articles_box = _resolveComponent("other-articles-box")!
  const _component_sidebar_box = _resolveComponent("sidebar-box")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { onClick: _ctx.scrollTop }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_notification_bell, {
                    parent: _ctx.$parent!.$refs.ionRouterOutlet
                  }, null, 8, ["parent"]),
                  _createVNode(_component_help_button)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { ref: "content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "w100" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, { class: "mx-4 pt-4" }, {
                            default: _withCtx(() => [
                              (!_ctx.loading && _ctx.event)
                                ? (_openBlock(), _createBlock(_component_ion_col, {
                                    key: 0,
                                    size: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        class: "flex",
                                        style: _normalizeStyle([{"border-radius":"2px"}, {
                      'border-left':
                        '3px solid rgb(' +
                        _ctx.getColor(_ctx.event.category ? _ctx.event.category.name : '') +
                        ')',
                    }])
                                      }, [
                                        _createElementVNode("div", _hoisted_1, [
                                          _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.event.Title), 1),
                                          (_ctx.event.startDate && _ctx.event.endDate)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.formatNicely(_ctx.event.startDate)) + " - " + _toDisplayString(_ctx.formatNicely(_ctx.event.endDate)) + " " + _toDisplayString(_ctx.timeOffset(_ctx.event.startDate)), 1))
                                            : _createCommentVNode("", true)
                                        ])
                                      ], 4)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.loading)
                                ? (_openBlock(), _createBlock(_component_ion_col, {
                                    key: 1,
                                    size: "10",
                                    class: "mt-4",
                                    style: {"margin-left":"48px"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"60%"} })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, { class: "mx-4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "12" }, {
                                default: _withCtx(() => [
                                  (_ctx.event)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        (_ctx.event.avatar)
                                          ? (_openBlock(), _createBlock(_component_ion_img, {
                                              key: 0,
                                              alt: "splash",
                                              class: "splash-placeholder auto",
                                              src: 
                        _ctx.event.avatar.formats.large
                          ? _ctx.event.avatar.formats.large.url
                          : _ctx.event.avatar.formats.medium
                          ? _ctx.event.avatar.formats.medium.url
                          : _ctx.event.avatar.url
                      
                                            }, null, 8, ["src"]))
                                          : _createCommentVNode("", true),
                                        (!_ctx.event.avatar)
                                          ? (_openBlock(), _createBlock(_component_splash_placeholder, {
                                              key: 1,
                                              class: "splash-placeholder auto",
                                              category: _ctx.event.category ? _ctx.event.category.name : ''
                                            }, null, 8, ["category"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (!_ctx.loading && _ctx.event)
                            ? (_openBlock(), _createBlock(_component_ion_row, {
                                key: 0,
                                class: "ion-justify-content-center mx-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, {
                                    "size-lg": "12",
                                    "size-md": "12",
                                    size: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.event.Details)
                                        ? (_openBlock(), _createElementBlock("pre", {
                                            key: 0,
                                            class: "px-2",
                                            innerHTML: _ctx.$marked(_ctx.event.Details)
                                          }, null, 8, _hoisted_5))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.loading)
                            ? (_openBlock(), _createBlock(_component_ion_row, {
                                key: 1,
                                class: "ion-justify-content-center mx-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, {
                                    "size-lg": "12",
                                    "size-md": "10",
                                    size: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_list, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mocks, (block) => {
                                            return (_openBlock(), _createBlock(_component_ion_item, {
                                              class: "px-4",
                                              lines: "none",
                                              key: block + '-block'
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_label, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("h3", null, [
                                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"90%"} })
                                                    ]),
                                                    _createElementVNode("p", null, [
                                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"100%"} })
                                                    ]),
                                                    _createElementVNode("p", null, [
                                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"92%"} })
                                                    ]),
                                                    _createElementVNode("p", null, [
                                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"90%"} })
                                                    ]),
                                                    _createElementVNode("p", null, [
                                                      _createVNode(_component_ion_skeleton_text, { style: {"width":"95%"} })
                                                    ])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.innerWidth > 1400)
                    ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_sidebar_box, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_resources_in_area),
                              _createVNode(_component_other_articles_box, { title: "Related Articles" })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}