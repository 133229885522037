
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { colorMixin } from "@/mixins/get.color";
import SplashPlaceholder from "@/components/common/SplashPlaceholder.vue";
import { documentOutline, openOutline } from "ionicons/icons";
import FavouriteIcon from "@/components/common/FavouriteIcon.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import VideoFrame from "@/components/common/VideoFrame.vue";
import FavModalButton from "@/components/common/FavModalButton.vue";
import SidebarBox from "@/components/common/SidebarBox.vue";
import UpcomingEventsBox from "@/components/common/UpcomingEventsBox.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";

export default defineComponent({
  name: "ArticlePage",
  mixins: [colorMixin],
  components: {
    BackButton,
    IonAvatar,
    IonButton,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonIcon,
    IonRow,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonLabel,
    IonList,
    IonListHeader,
    IonItem,
    IonImg,
    IonSkeletonText,
    SplashPlaceholder,
    FavouriteIcon,
    OtherArticlesBox,
    NotificationBell,
    HelpButton,
    VideoFrame,
    FavModalButton,
    SidebarBox,
    ResourcesInArea,
    UpcomingEventsBox
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    const mocks = [1, 2, 3];
    return {
      content,
      loading,
      mocks,
      documentOutline,
      openOutline,
    };
  },
  computed: {
    article(): any {
      return this.$store.state.article.article;
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    },
  },
  ionViewWillEnter() {
    const articleId = this.$route.params ? this.$route.params.id : null;
    if ((articleId && !this.article) || this.article.id != articleId) {
      this.$store.dispatch("article/getArticle", articleId);
    }
    this.loadOtherArticles();
  },
  methods: {
    async loadOtherArticles() {
      await this.$timer(300);
      this.loading = false;
      if (this.article) {
        await this.$store.dispatch(
          "article/getOtherArticles",
          {'category.name': this.article.category ? this.article.category.name : ""}
        );
      }
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
  },
});
