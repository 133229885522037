
import { colorMixin } from "@/mixins/get.color";
import { defineComponent } from "vue";
import {
  IonChip,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  IonLabel,
  IonRippleEffect,
} from "@ionic/vue";
export default defineComponent({
  name: "ContactListItem",
  mixins: [colorMixin],
  props: {
    contact: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["goto"],
  components: {
    IonChip,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonRippleEffect,
    IonThumbnail,
    IonImg,
    IonLabel,
  },
});
