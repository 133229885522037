
import { defineComponent } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
import YrpCircleLogo from "@/components/common/YrpCircleLogo.vue";

export default defineComponent({
  name: "Landing", 
  components: {
    IonContent,
    IonPage,
    YrpCircleLogo,
  },
  computed: {
    getUser(): any {
      return this.$store.state.user.myUser;
    },
    initComplete(): any {
      return this.$store.state.user.initComplete;
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      let breaker = false;
      let rounds = 0;
      const maxRounds = 4;
      while(!this.initComplete && !breaker) {
        await this.$timer(1000);
        rounds++;
        breaker = rounds > maxRounds;
      }
      if (!this.getUser) {
        // still not a user :(
        this.$router.replace("/welcome");
      } else {
        this.$router.replace("/home");
      }
    }
  }
});
