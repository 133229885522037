 export default {
    install: (app: any, options: any) => {
      app.config.globalProperties.$timer = (milliseconds?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, milliseconds ?? 3000)
        })
      }
    }
}
