import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "pl-1",
  style: {"line-height":"32px"}
}
const _hoisted_2 = { class: "text--dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (!_ctx.$route.path.includes('/dashboard'))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex my-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, [
        _createVNode(_component_ion_button, {
          fill: "empty",
          icon: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              color: "primary",
              icon: _ctx.chevronBack
            }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("strong", _hoisted_2, _toDisplayString(_ctx.$t("welcome.back")), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}