
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
import YrpLogo from "@/components/common/YrpLogo.vue";
import OnboardForm from  "@/components/onboard/OnboardForm.vue";

export default defineComponent({
  name: "Onboard",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    YrpLogo,
    OnboardForm
  },
  setup() {
    const loading = ref(false);
    const errorTxt = ref("");
    const snack = useSnackbarPlugin();
    return {
      loading,
      errorTxt,
      snack
    };
  },
  methods: {
    async onSubmit(values: { activationcode: string }) {
      this.loading = true;
      const errorMsg = await this.$store.dispatch("user/activate", values.activationcode);
      await this.$timer(1000);
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ?  this.errorTxt : this.$t("onboard.signUpFail"),
          time: 3000,
          close: true,
        });
        this.loading = false;
      } else {
        await this.$timer(1000);
        this.loading = false;
        this.$router.push("/success");
      }
      await this.$timer(2000);
      this.errorTxt = '';
    },
  },
});
