
import CheckInModal from "@/components/home/checkin/CheckInModal.vue";
import DistressModal from '@/components/home/DistressModal.vue';
import CheckInFab from "@/components/common/CheckInFab.vue";
import { defineComponent, ref } from "vue";
import { IonPage, IonTabs, IonRouterOutlet, modalController, isPlatform } from "@ionic/vue";
import { LocalNotifications } from "@capacitor/local-notifications";
export default defineComponent({
  name: "HomePage",
  components: {
    IonPage,
    IonRouterOutlet,
    IonTabs,
    CheckInFab,
  },
  computed: {
    pageName(): string {
      const route = this.$route.path;
      if (route.includes("dashboard")) {
        return "DASHBOARD";
      } else if (route.includes("bio")) {
        return "BIOLOGICAL";
      } else if (route.includes("psych")) {
        return "PSYCHOLOGICAL";
      } else if (route.includes("social")) {
        return "SOCIAL";
      } else if (route.includes("spirit")) {
        return "SPIRITUAL";
      } else if (route.includes("article")) {
        return "ARTICLES";
      } else if (route.includes("contacts")) {
        return "RESOURCES";
      } else if (route.includes("settings")) {
        return "SETTINGS";
      } else if (route.includes("search")) {
        return "SEARCH";
      } else if (route.includes("events")) {
        return "EVENTS";
      }  else if (route.includes("info")) {
        return "INFO";
      } else if (route.includes("help")) {
        return "HELP";
      }
      return "";
    },
  },
  setup() {
    const innerWidth = ref(0);
    const notificationsPossible = isPlatform("android") || isPlatform("ios");
    return {
      notificationsPossible,
      innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.setInnerWidth();
    if (this.notificationsPossible) {
        // add listener for notifications to home page. (and all sub pages, these are the auth'd pages)
        try {
        LocalNotifications.addListener("localNotificationActionPerformed", (async (action) => {
          if (action && action.actionId == 'tap') {
            this.openCompleteCheckIn()
            // since we opened the notification, lets schedule the next one!
            await this.$store.dispatch("user/clearLocalNotifications") // clear old ones jsut in case
            this.$store.dispatch("user/checkAndSetLocalNotifications")
          }
        }))
        } catch (err) {
          console.log('coould not set notification listeners.')
        }
    }

  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
    if (this.notificationsPossible) {
      LocalNotifications.removeAllListeners();
    }
  },
  methods: {
    setInnerWidth() {
      // your code for handling resize...
      this.innerWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
        if (this.innerWidth) {
          this.$store.dispatch('user/saveInnerWidth', this.innerWidth)
        }
    },
    myEventHandler(e: Event) {
      this.setInnerWidth()
    },
    async openCompleteCheckIn() {
      const modal = await modalController.create({
        component: CheckInModal,
        mode: 'ios',
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
    async openInDistress() {
      const modal = await modalController.create({
        mode: 'ios',
        component: DistressModal,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
  },
});
