
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonList,
  IonLabel,
  IonItem,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { colorMixin } from "@/mixins/get.color";
import SplashPlaceholder from "@/components/common/SplashPlaceholder.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";
import { dateMixin } from "@/mixins/date.format";
import SidebarBox from "@/components/common/SidebarBox.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";

export default defineComponent({
  name: "EventPage",
  mixins: [colorMixin, dateMixin],
  components: {
    BackButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonLabel,
    IonList,
    IonItem,
    IonImg,
    IonSkeletonText,
    SplashPlaceholder,
    NotificationBell,
    HelpButton,
    OtherArticlesBox,
    ResourcesInArea,
    SidebarBox
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    const mocks = [1, 2, 3];
    return {
      content,
      loading,
      mocks,
    };
  },
  computed: {
    event(): any {
      return this.$store.state.event.event;
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    },
  },
  ionViewWillEnter() {
    const eventId = this.$route.params ? this.$route.params.id : null;
    if ((eventId && !this.event) || this.event.id != eventId) {
      this.$store.dispatch("event/getEvent", eventId);
    }
    this.loadOtherArticles();
  },
  methods: {
    async loadOtherArticles() {
      await this.$timer(300);
      this.loading = false;
      if (this.event) {
        await this.$store.dispatch(
          "article/getOtherArticles",
           {'category.name': this.event.category ? this.event.category.name : ""}
        );
      }
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
    formatNicely(dateISOString: string) {
      return this.dateFormat("MMM DD, h:mm a", dateISOString);
    },
    timeOffset(dateISOString: string) {
      return this.dateFormat("ZZZZ", dateISOString);
    },
  },
});
