
import { defineComponent } from "vue";
import { IonBadge, IonButton, IonIcon, modalController } from "@ionic/vue";
import { notificationsOutline } from "ionicons/icons";
import HelpNowModal from "@/components/home/HelpNowModal.vue";
import { UserDetails } from "@/models/userDetails";

export default defineComponent({
  name: "NotificationBell",
  props: {
    parent: {
      default: undefined,
    }
  },
  components: {
    IonBadge,
    IonButton,
    IonIcon,
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    latestCheckInAverage(): number {
      return this.$store.getters["check/latestCheckInAverage"];
    },
  },
  methods: {
    async openHelpNowModal() {
      const modal = await modalController.create({
        mode: 'ios',
        component: HelpNowModal,
        presentingElement: this.parent,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
  },
  setup() {
    return { notificationsOutline };
  },
});
