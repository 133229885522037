import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "headline-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_ctx.otherArticles && _ctx.otherArticles.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherArticles, (oArticle) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                onClick: ($event: any) => {
          _ctx.$store.dispatch('article/getArticle', oArticle.id);
          _ctx.$router.push('/home/articles/' + oArticle.id);
        },
                button: "",
                lines: "none",
                key: 'art-' + oArticle.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" > " + _toDisplayString(oArticle.title ?? ""), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}