
import { defineComponent, ref } from "vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  IonContent,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToggle,
  IonicSwiper,
  modalController,
  isPlatform,
} from "@ionic/vue";
import WellnessCircle from "@/components/common/WellnessCircle.vue";
import RegionSelector from "./RegionSelector.vue";
import { useSnackbarPlugin } from "snackbar-vue";
import "swiper/swiper-bundle.min.css";
import "@ionic/vue/css/ionic-swiper.css";
import { LocalNotifications } from "@capacitor/local-notifications";
import CloseButton from "@/components/common/CloseButton.vue";

SwiperCore.use([Navigation, Pagination, IonicSwiper]);

export default defineComponent({
  components: {
    IonButton,
    IonContent,
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonToggle,
    Swiper,
    SwiperSlide,
    WellnessCircle,
    RegionSelector,
    CloseButton
  },
  computed: {
    myDetails(): any {
      return this.$store.state.user.myDetails;
    },
  },
  ionViewDidLeave() {
    if (this.slides) {
      this.slides.destroy();
    }
  },
  methods: {
    slideForward() {
      this.slides.slideNext(300, false);
    },
    slideBack() {
      this.slides.slidePrev(300, false);
    },
    onSwiper(swiper: any) {
      this.slides = swiper;
      this.activeIndex = swiper.activeIndex;
    },
    moop(swiper: any) {
      this.activeIndex = swiper.activeIndex;
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push("/welcome");
      location.reload(); // TODO are you sure.
    },
    async onSubmit() {
      this.loading = true;
      // update wizard Completed!
      this.wizardCompleted = true;
      const errorMsg = await this.$store.dispatch("user/updateUserDetails", {
        notificationsEnabled: this.notificationsEnabled ?? false,
        interval: this.interval ?? "weekly",
        wizardCompleted: this.wizardCompleted ?? true,
      });
      await this.$timer(1500);
      if (errorMsg) {
        this.errorTxt = errorMsg;
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : "form submission failed.",
          time: 3000,
          close: true,
        });
        this.loading = false;
      } else {
        this.loading = false;

        modalController.dismiss(true);
        this.snack.show({
          position: "bottom",
          text: "Success. Onboarding complete.",
          time: 2500,
          close: true,
        });
      }
      await this.$timer(2000);
      this.errorTxt = "";
    },
    // these are copied from Settings.vue :(
    async toggleNotifications() {
      if (this.loading2 || !this.notificationsPossible) return;
      // if this is set, we already have this on.
      // so all we are doing is saving set to false.
      if (this.myDetails && this.myDetails.notificationsEnabled) {
        await this.saveChanges();
        this.$store.dispatch("user/clearLocalNotifications"); // clear old ones.
        // we are not yet enabled, so lets check
      } else {
        const result = await LocalNotifications.requestPermissions();
        if (result.display == "granted") {
          await this.saveChanges();
          await this.$store.dispatch("user/clearLocalNotifications");
          this.$store.dispatch("user/checkAndSetLocalNotifications");
        } else {
          this.notificationsEnabled = false; // force this to false.
          this.snack.show({
            position: "bottom",
            text: "notification permissions were denied.",
            time: 3000,
            close: true,
          });
        }
      }
    },
    async toggleInterval() {
      if (this.loading2 || !this.notificationsPossible) return;
      if (this.myDetails && (this.myDetails.interval == this.interval)) {
        this.saveChanges();
      } else {
        // else means we changed the interval, have to reset our next notification.
        await this.saveChanges();
        await this.$store.dispatch("user/clearLocalNotifications");
        this.$store.dispatch("user/checkAndSetLocalNotifications");
      }
    },
    async saveChanges() {
      this.loading2 = true;
      const errorMsg = await this.$store.dispatch("user/updateUserDetails", {
        interval: this.interval,
        notificationsEnabled: this.notificationsEnabled,
      });
      if (errorMsg && errorMsg.length) {
        this.snack.show({
          position: "bottom",
          text: errorMsg,
          time: 3000,
          close: true,
        });
      }
      await this.$timer(1000);
      this.loading2 = false;
    },
    close() {
      modalController.dismiss();
    },
  },
  setup() {
    const loading = ref(false);
    const loading2 = ref(false);
    const errorTxt = ref("");
    const slides = ref();
    const activeIndex = ref(0);
    const snack = useSnackbarPlugin();
    const notificationsEnabled = ref(false);
    const interval = ref("weekly");
    const wizardCompleted = ref(false);
    const circles = ["bio", "psych", "social", "spiritual"];
    const notificationsPossible = isPlatform("android") || isPlatform("ios");
    const notificationsPermission = ref(""); // onswiper fires near mounted and inits this.slides with swiper.
    return {
      activeIndex,
      snack,
      notificationsEnabled,
      interval,
      wizardCompleted,
      circles,
      errorTxt,
      loading,
      loading2,
      slides,
      notificationsPossible,
      notificationsPermission,
    };
  },
  ionViewWillEnter() {
    if (this.myDetails) {
      this.interval = this.myDetails.interval;
      this.notificationsEnabled = this.myDetails.notificationsEnabled ?? false;
      this.wizardCompleted = this.myDetails.wizardCompleted ?? false;
    }
  },
    closeAndDismiss() {
      modalController.dismiss();
      this.$store.dispatch("user/updateUserDetails", { dismissHeader: true });
    },
});
