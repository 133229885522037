import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","height":"100%"} }
const _hoisted_2 = { class: "auto" }
const _hoisted_3 = { class: "headline-5 ion-text-center" }
const _hoisted_4 = { style: {"position":"absolute","bottom":"48px","width":"100px","text-align":"center","left":"calc(50% - 50px)","opacity":"0.3"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yrp_logo = _resolveComponent("yrp-logo")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        class: "flex"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_yrp_logo, { class: "auto" })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "12",
                        class: "mt-3"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t("welcome.message")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4 mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-lg": "3",
                        "size-md": "4",
                        size: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            class: "button-blue",
                            expand: "full",
                            onClick: _ctx.login
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("welcome.loginButton")) + "   ", 1),
                              _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "size-lg": "3",
                        "size-md": "4",
                        size: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            class: "button-white",
                            expand: "full",
                            onClick: _ctx.onboard
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("welcome.signupButton")) + "   ", 1),
                              _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-lg": "6",
                        "size-md": "8",
                        size: "10",
                        class: "ion-text-center"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "body-2 my-underline my-pointer",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPrivacyPolicy && _ctx.openPrivacyPolicy(...args)))
                          }, _toDisplayString(_ctx.$t("welcome.privacy")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.version), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}