import { Module } from 'vuex';
import { Article } from '@/models/article';
import { ContentService } from '@/services/strapi.service';
import { whereOr } from '@/mixins/strapi.format';
import { firebaseService } from '@/main' ;
import { formatArticleQuery } from '@/services/qs.service';
import { formatFilter } from '@/services/meil.service';

interface ArticleModuleState {
  articles: Article[];
  otherArticles: Article[];
  article: Article;
  shellArticle: Article;
  favArticles: any[];
  pageSize: number;
  offset: number;
  hasMore: boolean;
}

export interface MeilisearchResult<T> {
    hits: T[];
    nbHits: number;
    exhaustiveNbHits: boolean;
    query: string;
    limit: number;
    offset: number;
    processingTimeMs: number;
}

const getDefaultState = () => {
  return {
    articles: [],
    otherArticles: [],
    article: null as any,
    shellArticle: null as any,
    favArticles: [],
    pageSize: 10,
    offset: 0,
    hasMore: true
  }
}

export const articleModule: Module<ArticleModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getRecom({ rootGetters, rootState, commit, state }): Promise<string> {
      try {
        commit('setOffset', 0)
        const post = {
          roles : rootState.user.myDetails ? rootState.user.myDetails.roles : [],
          score : rootGetters['check/latestCheckInAverage'] ?? 0,
          topics : rootGetters['check/topics'] ?? [],
          limit: state.pageSize + 1,
          offset: 0
          }

        const articles = await ContentService.getRecomm(post)        
        if (articles) {
          commit('setHasMore', articles.length > state.pageSize)
          commit('setArticles', articles.slice(0, state.pageSize))
          return ''
        } else {
          commit('setArticles', [])
          return 'could not load recomm'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get recomm.';
      }
    },
    async getMoreRecom({ rootGetters, rootState, commit, state }): Promise<string> {
      try {
        if (!state.hasMore) {
          return ''
        }
        const post = {
          roles : rootState.user.myDetails ? rootState.user.myDetails.roles : [],
          score : rootGetters['check/latestCheckInAverage'] ?? 0,
          topics : rootGetters['check/topics'] ?? [],
          limit: state.pageSize + 1,
          offset: state.offset + state.pageSize
          }

        const articles = await ContentService.getRecomm(post)
        commit('setOffset', state.offset + state.pageSize)     
        if (articles) {
          commit('setHasMore', articles.length > state.pageSize)
          commit('addArticles', articles.slice(0, state.pageSize))
          return ''
        } else {
          // commit('setArticles', [])
          return 'could not add recomm articles'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get more recomm.';
      }
    },

    async getArticles({ rootState, commit, state }, query): Promise<string> {
      try {
        commit('setOffset', 0)
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const articles = await ContentService.getMany<Article>('articles', formatArticleQuery(query, roles, state.pageSize + 1, 0))
        if (articles) {
          commit('setHasMore', articles.length > state.pageSize)
          commit('setArticles', articles.slice(0, state.pageSize))
          return ''
        } else {
          commit('setArticles', [])
          return 'could not load articles'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get articles.';
      }
    },
    async getMoreArticles({ rootState, commit, state }, query): Promise<string> {
      try {
        if (!state.hasMore) {
          return ''
        }
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const articles = await ContentService.getMany<Article>('articles', formatArticleQuery(query, roles, state.pageSize + 1, state.offset + state.pageSize))
        commit('setOffset', state.offset + state.pageSize)
        if (articles) {
          commit('setHasMore', articles.length > state.pageSize)
          commit('addArticles', articles.slice(0, state.pageSize))
          return ''
        } else {
          // commit('setArticles', [])
          return 'could not add articles'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get articles.';
      }
    },
    async getOtherArticles({ rootState, state, commit }, query): Promise<string> {
      try {
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const articles = await ContentService.getMany<Article>('articles', formatArticleQuery(query, roles, state.pageSize / 2, 0))        
        if (articles) {
          commit('setOtherArticles', articles)
          return ''
        } else {
          commit('setOtherArticles', [])
          return 'could not load articles'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get articles.';
      }
    },
    async loadFavAsOtherArticles({state,  commit }, category?: string): Promise<string> {
      try {
        const query = '?' + whereOr('id', state.favArticles.filter(f=>f.favourite).map(f=> f.id)) + (category ? `&category.name=${category}` : ``)
        const articles = await ContentService.getMany<Article>('articles', query)
        if (articles) {
          commit('setOtherArticles', articles)
          return ''
        } else {
          commit('setOtherArticles', [])
          return 'could not load fav articles'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get fav articles.';
      }
    },
    async getArticle({ commit }, articleId): Promise<string> {
      try {
        const article = await ContentService.getOne<Article>('articles', articleId)
        if (article) {
          commit('setArticle', article)
          // also make a stats update.
          firebaseService.updateStats({entity: 'articles', id: articleId})
          firebaseService.getAnalytics().logEvent('article_read');
          return ''
        } else {
          commit('setArticle', null)
          return 'could not load article'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get article.';
      }
    },
    async getShellArticle({ rootGetters, commit }): Promise<string> {
      try {
        if (rootGetters['check/latestCheckInAverage'] == null) {
          return 'didnt load shellArticle'; // don't bother
        }
        let level = '1-low'
        if (rootGetters['check/latestCheckInAverage'] >= 60) {
          level = '1-low'
        } else if (rootGetters['check/latestCheckInAverage'] >= 35) {
          level = '2-med'
        } else if (rootGetters['check/latestCheckInAverage'] > 0) {
          level = '3-high'
        }

        const articles = await ContentService.getMany<Article>('articles', `?shell=true&priorityLevel=${level}&_limit=1`)
        if (articles && articles.length) {
          commit('setShellArticle', articles[0])
          return ''
        } else {
          commit('setShellArticle', null)
          return 'could not load shellArticle'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get shellArticle.';
      }
    },
    async getFavArticles({ rootState, commit } ): Promise<string> {
      try {
        const snapshot = await firebaseService.getFavouriteArticles(rootState.user.myUser.uid);
        commit('setFavArticles', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on fav-articles load.';
      }
    },
    async saveFavArticle({ rootState, commit }, articleId ): Promise<string> {
      try {
        await firebaseService.setFavouriteArticle(rootState.user.myUser.uid, articleId);
        const snapshot = await firebaseService.getFavouriteArticles(rootState.user.myUser.uid);
        commit('setFavArticles', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on fav-articles set.';
      }
    },
    async unFavArticle({ rootState, commit }, articleId ): Promise<string> {
      try {
        await firebaseService.unFavouriteArticle(rootState.user.myUser.uid, articleId);
        const snapshot = await firebaseService.getFavouriteArticles(rootState.user.myUser.uid);
        commit('setFavArticles', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on fav-articles set.';
      }
    },
    async getSearchArticles({ rootState, state, commit }, search): Promise<string> {
      try {
      const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
      const data: MeilisearchResult<Article> = await ContentService.runSearch<Article>({
        entity: 'articles',
        search,
        offset: 0,
        limit: state.pageSize,
        filter: 'shell = false AND ' + formatFilter(roles)
      })

      commit('setOffset', 0)
      commit('setHasMore', data.nbHits > (data.hits.length))
      commit('setArticles', data.hits)
      return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on search articles.';
      }
    },
    async getMoreSearchArticles({ rootState, state, commit }, search): Promise<string> {
      if (!state.hasMore) {
        return ''
      }
      try {
      commit('setOffset', state.offset + state.pageSize)
      const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
      const data: MeilisearchResult<Article> = await ContentService.runSearch<Article>({
        entity: 'articles',
        search,
        offset: state.offset,
        limit: state.pageSize,
        filter: 'shell = false AND ' + formatFilter(roles)
      })

      commit('setHasMore', data.nbHits > (state.articles.length + data.hits.length))
      commit('addArticles', data.hits)
      return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on search articles.';
      }
    }
},
  mutations: {
    setArticles(state, articles) {
      state.articles = []
      state.articles = articles
    },
    addArticles(state, articles) {
      state.articles = [...state.articles, ...articles];
    },
    setOtherArticles(state, articles) {
      state.otherArticles = articles
    },
    setArticle(state, article) {
      state.article = article
    },
    setShellArticle(state, article) {
      state.shellArticle = article
    },
    setFavArticles(state, snapshot) {
      state.favArticles = [];
      snapshot.forEach((doc: any) => {
        const c = doc.data();
        c.id = doc.id
        state.favArticles.push(c);
      });
    },

    setOffset(state, offset) {
      state.offset = offset;
    },
    setHasMore(state, more) {
      state.hasMore = more;
    },
  }
}
