
import { defineComponent } from "vue";
import {
  IonList,
  IonItem,
  IonIcon,
  IonChip,
  IonLabel,
  IonImg,
} from "@ionic/vue";
import { chevronBack } from "ionicons/icons";
import { colorMixin } from "@/mixins/get.color";
export default defineComponent({
  mixins: [colorMixin],
  props: {
    highlightContact: {
      type: Object,
      default: null,
    },
  },
  emits: ["filterSearch", "clearHighlight"],
  components: {
    IonList,
    IonItem,
    IonIcon,
    IonChip,
    IonLabel,
    IonImg,
  },
  setup() {
    return { chevronBack };
  },
});
