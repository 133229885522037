
import { defineComponent, ref } from "vue";
import {
  IonBackdrop,
  IonFabButton,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonRippleEffect,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import {
  closeOutline,
  menuOutline,
  settingsOutline,
  logOutOutline,
  checkmarkCircleOutline,
  peopleOutline,
  searchOutline,
  calendarOutline,
  helpBuoyOutline
} from "ionicons/icons";
import CircleProgress from "vue3-circle-progress";
import YrpCircleLogo from "./YrpCircleLogo.vue";

export default defineComponent({
  name: "CheckInFab",
  components: {
    IonBackdrop,
    IonButton,
    IonFabButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonRippleEffect,
    IonGrid,
    IonRow,
    IonCol,
    CircleProgress,
    YrpCircleLogo,
  },
  emits: ['openCheck', 'openDistress'],
  props: {
    pageName: {
      type: String,
      default: "",
    },
  },
  setup() {
    const isOpen = ref(false);
    return {
      closeOutline,
      menuOutline,
      settingsOutline,
      logOutOutline,
      checkmarkCircleOutline,
      peopleOutline,
      searchOutline,
      isOpen,
      calendarOutline,
      helpBuoyOutline
    };
  },
  computed: {
    latestCheckIn(): any {
      return this.$store.getters["check/latestCheckIn"];
    },
  },
  methods: {
    triggerScrollTop() {
      this.$store.dispatch('user/triggerScrollTop')
    },
    logout() {
      this.$store.dispatch('user/logout').then(()=> {
        this.$router.push('home/dashboard');
      })
    }
  }
});
