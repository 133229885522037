import { QuestionResponse } from "./questionResponse";
import { FirestoreDate } from "./userDetails";

/*
CheckIn MODEL
    {
        biological: 11,
        created: August 5mt242kj
        counted: true
        psychological: 33,
        responses: [],
        social: 23,
        spiritual: 77,
    }
*/
export class CheckIn {
  constructor(
    public biological?: number,
    public created?: FirestoreDate | Date,
    public counted?: boolean,
    public psychological?: number,
    public responses?: QuestionResponse[],
    public social?: number,
    public spiritual?: number,
    // public id?: string
  ) { }
}

// Firestore data converter
export const checkConverter = {
  //to
  toFirestore: function (check: CheckIn) {
    return {
      biological: check.biological,
      created: check.created,
      counted: check.counted,
      psychological: check.psychological,
      responses: check.responses,
      social: check.social,
      spiritual: check.spiritual,
      //    id: check.id
    };
  },
  // firebase.firestore.QuerySnapshot<CheckIn>
  fromFirestore: function (snapshot: any) {
    const data = snapshot.data();
    return new CheckIn(
      data.biological,
      data.created,
      data.counted,
      data.psychological,
      data.responses,
      data.social,
      data.spiritual,
      //   data.id
    );
  }
};