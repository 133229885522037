// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics"

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import 'firebase/app-check';
import 'firebase/functions';
import { UserDetails } from "@/models/userDetails";
import { CheckIn } from "@/models/checkIn";
import { QuestionResponse } from "@/models/questionResponse";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcTPyGllzOS-2oqZnQkaMcjz13cVe96Os",
  authDomain: "fs-wellnessfirst.firebaseapp.com",
  projectId: "fs-wellnessfirst",
  storageBucket: "fs-wellnessfirst.appspot.com",
  messagingSenderId: "712843283667",
  appId: "1:712843283667:web:b8c21da85f96b81ee41826",
  measurementId: "G-FVDK82VKVT"
};

export class FirebaseService {
  public auth: firebase.auth.Auth | null = null
  public db: firebase.firestore.Firestore | null = null
  public fcn: firebase.functions.Functions | null = null
  public analytics: firebase.analytics.Analytics | null = null

  constructor() {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    // if (process.env.NODE_ENV == 'development') {
    // firebase.functions().useEmulator("localhost", 5001);
    // }
  }

  public getAuth(): firebase.auth.Auth {
    if (this.auth == null) {
      this.auth = firebase.auth()
    }
    return this.auth
  }
  public getDb(): firebase.firestore.Firestore {
    if (this.db == null) {
      this.db = firebase.firestore()
    }
    return this.db
  }
  public getFcns(): firebase.functions.Functions {
    if (this.fcn == null) {
      this.fcn = firebase.app().functions();
    }
    return this.fcn
  }

  public getAnalytics(): firebase.analytics.Analytics {
    if (this.analytics == null) {
      this.analytics = firebase.analytics();
    }
    return this.analytics
  }

  public onAuthStateChange(ftn: any, errFcn: any) {
    return this.getAuth().onAuthStateChanged(ftn, errFcn)
  }

  public async createUser(): Promise<firebase.auth.UserCredential> {
    return this.getAuth().signInAnonymously() // creates new anonymous user.
  }

  public async login(phrase: string) {
    const getToke = this.getFcns().httpsCallable('getToken');
    const result = await getToke({phrase: phrase});
    if (result) {
      return this.getAuth().signInWithCustomToken(result.data.token)
    }
  }

  public async logout(): Promise<void> {
    return this.getAuth().signOut()
  }

  public async activateUserAndLogin(formData: {activationCode: string; check: boolean}): Promise<string> {
    const activate = this.getFcns().httpsCallable('activateUser');
    const result = await activate(formData)
    // result data has the token and the secret phrase for the new user. SUPER COOL!
    if (result && !formData.check) { 
      await this.getAuth().signInWithCustomToken(result.data.token)
      return result.data.phrase
    }
    return ''
  }

  public async updateUserDetails(uid: string, userDetails: any): Promise<void> {
    return this.getDb().collection("userDetails")
      .doc(uid)
      .update(userDetails)
  }

  public async getUserDetails(uid: string): Promise<firebase.firestore.QuerySnapshot<UserDetails>> {
    return this.getDb().collection("userDetails")
      .where(firebase.firestore.FieldPath.documentId(), '==', uid)
      .get()
  }

  public async loadCheckIns(uid: string): Promise<firebase.firestore.QuerySnapshot<CheckIn>> {
    return this.getDb().collection(`userDetails/${uid}/checkIns`)
    .orderBy("created", "desc")
    .limit(7) // only loading the last 7?
    .get()
  }

  public async submitCheckIn(checkIn: CheckIn): Promise<any> {
    const checkMeIn = this.getFcns().httpsCallable('checkIn');
    const result = await checkMeIn(checkIn);
    if (result) {
      return result.data
    }
  }

  public async loadQuestionResponses(uid: string): Promise<firebase.firestore.QuerySnapshot<QuestionResponse>> {
    const snapshot = await this.getDb().collection(`userDetails/${uid}/checkIns`)
    .orderBy("created", "desc")
    .limit(7).get()
    const checkInIds: string[] = [];
    snapshot.forEach(doc => {
      checkInIds.push(doc.id)
    })
    return this.getDb().collectionGroup('questionResponses').where('checkInId', 'in', checkInIds.length ? checkInIds : ['232notreal213']).get()
  }

  public async setFavouriteArticle(uid: string, articleId: string): Promise<void> {
    return this.getDb().collection("userDetails")
      .doc(uid)
      .collection("articles")
      .doc(articleId.toString())
      .set({
        favourite: true // set read, set skip, etc.
      })
  }
  public async unFavouriteArticle(uid: string, articleId: string): Promise<void> {
    return this.getDb().collection("userDetails")
      .doc(uid)
      .collection("articles")
      .doc(articleId.toString())
      .set({
        favourite: false // set read, set skip, etc.
      })
  }
  // [{id: '', favourite: true}]
  public async getFavouriteArticles(uid: string): Promise<firebase.firestore.QuerySnapshot<any>> {
    return this.getDb().collection("userDetails")
      .doc(uid)
      .collection("articles")
      .get()
  }

  public async updateStats(stat: any): Promise<string> {
    if (process.env.NODE_ENV == "development") {
      return ''; // dont bother updating in dev.
    }
    const activate = this.getFcns().httpsCallable('callUpdateStats');
    await activate(stat)
    return ''
  }

  public getToken(): Promise<string> | undefined  {
    return this.getAuth().currentUser?.getIdToken();
  }
}