
import { defineComponent, ref } from "vue";
import { thumbsDownOutline  } from "ionicons/icons";
import { colorMixin } from "@/mixins/get.color";
import { IonIcon } from "@ionic/vue";
import { Contact } from "@/models/contact";
import ContactListItem from '@/components/home/contacts/ContactListItem.vue';

export default defineComponent({
  name: "ContactFeed",
  mixins: [colorMixin],
  components: {
    IonIcon,
    ContactListItem
  },
  setup() {
    const loading = ref(true);
    return { thumbsDownOutline, loading }
  },
  async mounted() {
    this.$timer(2000);
    this.loading = false;
  },
  computed: {
    contacts(): Contact[] {
      return this.$store.state.contact.contacts;
    },
  },
});
