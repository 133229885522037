import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mocks, (block) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          class: "pa-4",
          lines: "none",
          key: block + '-block'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_skeleton_text)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, [
                  _createVNode(_component_ion_skeleton_text, { style: {"width":"60%"} })
                ]),
                _createElementVNode("p", null, [
                  _createVNode(_component_ion_skeleton_text, { style: {"width":"100%"} })
                ]),
                _createElementVNode("p", null, [
                  _createVNode(_component_ion_skeleton_text, { style: {"width":"92%"} })
                ]),
                _createElementVNode("p", null, [
                  _createVNode(_component_ion_skeleton_text, { style: {"width":"90%"} })
                ]),
                _createElementVNode("p", null, [
                  _createVNode(_component_ion_skeleton_text, { style: {"width":"95%"} })
                ])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}