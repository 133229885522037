
import { defineComponent, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
import { heartOutline, heart } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
export default defineComponent({
  name: 'FavouriteIcon',
  props: {
    articleId: null
  },
  components: {
    IonIcon
  },
  setup() {
    const loading = ref(false);
    const localChange = ref(null as any);
    const snack = useSnackbarPlugin();
    return { heart, heartOutline, localChange, snack, loading };
  },
  computed: {
    favArticles(): any[] {
      return this.$store.state.article.favArticles;
    },
  },
  methods: {
    async favArticle(articleId: string) {
      if (this.loading) return;
      if (this.favArticles && this.favArticles.length >= 10) {
        this.snack.show({
          position: "bottom",
          text: "Sorry, you already have 10 favourites.",
          time: 2000,
          close: true,
        });
      return
      }
      this.localChange = true;
      this.loading = true;
      const error = await this.$store.dispatch(
        "article/saveFavArticle",
        articleId
      );
      this.snack.show({
        position: "bottom",
        text: error ? error : "Added to favourites.",
        time: 2000,
        close: true,
      });
      if (error) {
        this.localChange = false;
      }
      this.loading = false;
    },
    async unFavArticle(articleId: string) {
      if (this.loading) return;
      this.localChange = false;
      this.loading = true;
      const error = await this.$store.dispatch(
        "article/unFavArticle",
        articleId
      );
      this.snack.show({
        position: "bottom",
        text: error ? error : "Removed from favourites.",
        time: 2000,
        close: true,
      });
      if (error) {
        this.localChange = true;
      }
      this.loading = false;
    },
    isFav(articleId: any): boolean {
      if (this.favArticles.length == 0) {
        return false;
      }
      return (
        -1 !=
        this.favArticles.findIndex((a) => a.favourite && a.id == articleId)
      );
    },
  },
});
