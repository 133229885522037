
import { defineComponent } from "vue";
import { EventEntity } from "@/models/event";
import EventFeed from "@/components/home/dashboard/EventFeed.vue"

export default defineComponent({
  name: "UpcomingEventsBox",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: {
    EventFeed
  },
  computed: {
    events(): EventEntity[] {
      return this.$store.state.event.events.slice(0,3);
    },
  },
});
