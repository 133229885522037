
import {
  IonButton,
  IonContent,
  IonIcon,
  IonTextarea,
  IonItem,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonText
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
import YrpLogo from "@/components/common/YrpLogo.vue";
import { chevronForward, copyOutline } from "ionicons/icons";
import useClipboard from "@/plugins/vue-copy";

export default defineComponent({
  name: "Onboard",
  components: {
    IonButton,
    IonContent,
    IonIcon,
    IonTextarea,
    IonItem,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonText,
    YrpLogo,
  },
  setup() {
    const copied = ref(false)
    const snack = useSnackbarPlugin()
    const { toClipboard } = useClipboard()
    const copy = async (text: string) => {
      try {
        await toClipboard(text)
        snack.show({
          position: "bottom",
          text: "Phrase copied to clipboard."
        });
      } catch (e) {
        console.error(e)
      }
    }
    return {
      chevronForward,
      copyOutline,
      copy,
      snack,
      copied
    };
  },
  computed: {
    getPhrase(): any {
      return this.$store.state.user.myPhrase
    }
  },
  ionViewDidLeave() {
    this.$store.dispatch('user/clearPhrase')
  }
});
