import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_favourite_icon = _resolveComponent("favourite-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_ctx.favArticles && _ctx.favArticles.length)
    ? (_openBlock(), _createBlock(_component_ion_button, {
        key: 0,
        class: "ion-hide-md-up o-87",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFavModal()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_favourite_icon, { articleId: _ctx.articleId }, null, 8, ["articleId"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}