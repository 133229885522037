
import { defineComponent } from "vue";
import CircleProgress from "vue3-circle-progress";
export default defineComponent({
  name: "WellnessCircle",
  components: {
    CircleProgress,
  },
  props: {
    scale: {
      type: String,
      default: "1.0",
    },
    size: {
      type: Number,
      default: 180,
    },
    one: {
      type: Number,
      default: 0,
    },
    two: {
      type: Number,
      default: 0,
    },
    three: {
      type: Number,
      default: 0,
    },
    four: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    total(): any {
      let dividend = 400
      if (this.one === 0) {
        dividend -= 100;
      } 
      if (this.two === 0) {
        dividend -= 100;
      } 
      if (this.three === 0) {
        dividend -= 100;
      }
      if (this.four === 0) {
        dividend -= 100;
      }
      return Math.round(((this.one + this.two + this.three + this.four) / dividend ) * 100) || 100
    }
  }
});
