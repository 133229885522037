
import { defineComponent, ref } from 'vue'
import { GoogleMap, Marker, CustomControl } from 'vue3-google-map'
import { locate } from "ionicons/icons";
import { IonFabButton, IonIcon } from "@ionic/vue";

export default defineComponent({
  name: 'ContactGoogleMap',
  components: { GoogleMap, Marker, CustomControl, IonFabButton, IonIcon },
  props: {
    zoom: {
      type: Number,
      default: 10
    },
    height: {
      type: String,
      default: '500px'
    },
    center: {
      type: Object,
      default: null
    },
    markers: {
      type: Array,
      default: null
    },
    searchbar: {
      type: Boolean,
      default: false
    },
    find: {
      type: Boolean,
      default: false
    }
  },
  emits: ["goto", "findme"],
  setup() {
    const apiKey = process.env.VUE_APP_GOOGLE_API ?? '';
    const center2 = { lat: 40.690047, lng: -74.044502 }
    const mapRef = ref(null as any)
    return { mapRef, center2, apiKey, locate }
  },
  methods: {
    formatMark(marker: any) {
      if (!this.mapRef) return
      if (marker && marker.icon && marker.icon.pointX && this.mapRef.api != null) {
        marker.icon.labelOrigin = new this.mapRef.api.Point(marker.icon.pointX, marker.icon.pointY)
        delete marker.icon.pointX
        delete marker.icon.pointY
      }
      return marker
    }
  }
})
