import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_chip, {
      color: "primary",
      outline: "",
      onClick: _ctx.openPopover
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            _createElementVNode("strong", null, _toDisplayString(_ctx.myRegion ? _ctx.myRegion.name : _ctx.$t("wizard.selectARegion")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}