export const colorMixin = {
  methods: {
  getColor(category: string) {
    switch (category.toLowerCase()) {
      case "biological":
        return "153, 202, 60";
      case "psychological":
        return "88, 196, 235";
      case "social":
        return "244, 123, 75";
      case "spiritual":
        return "154, 87, 163";
      default:
        return "255, 255, 255";
      }
    }
  }
}