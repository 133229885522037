
import { defineComponent, ref } from "vue";
import { IonIcon } from "@ionic/vue";
import { thumbsDownOutline } from "ionicons/icons";
import { Article } from "@/models/article";
import ArticleCard from "@/components/home/dashboard/ArticleCard.vue";

export default defineComponent({
  name: "ArticlesFeed",
  components: {
    IonIcon,
    ArticleCard,
  },
  props: {
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const loading = ref(true);
    return { thumbsDownOutline, loading };
  },
  async mounted() {
    await this.$timer(2000);
    this.loading = false;
  },
  computed: {
    articles(): Article[] {
      return this.$store.state.article.articles;
    },
    otherArticles(): Article[] {
      return this.$store.state.article.otherArticles;
    },
    shellArticle(): Article {
      return this.$store.state.article.shellArticle;
    }
  },
});
