
import { defineComponent } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
import YrpCircleLogo from "@/components/common/YrpCircleLogo.vue";

export default defineComponent({
  name: "NotFound", 
  components: {
    IonContent,
    IonPage,
    YrpCircleLogo,
  },
  async mounted() {
    this.$router.push("/landing");
  }
});
