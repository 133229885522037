import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "headline-5" }
const _hoisted_2 = {
  key: 0,
  class: "pa-4 ion-text-center"
}
const _hoisted_3 = { class: "body-2 o-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_article_card = _resolveComponent("article-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_close_button, { onClick: _ctx.close }, null, 8, ["onClick"]),
      _createVNode(_component_ion_grid, { class: "px-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-justify-content-center" }),
          _createVNode(_component_ion_row, { class: "ion-text-start px-4" }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("favourite.title")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "pa-4" }, {
            default: _withCtx(() => [
              (_ctx.otherArticles)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    "size-lg": "12",
                    "size-md": "12",
                    size: "12"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherArticles, (oArticle) => {
                        return (_openBlock(), _createBlock(_component_article_card, {
                          article: oArticle,
                          key: 'art1-' + oArticle.id,
                          onClick: ($event: any) => {_ctx.close(); _ctx.$router.push('/home/articles/' + oArticle.id);}
                        }, null, 8, ["article", "onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  (!_ctx.loading && (!_ctx.otherArticles || _ctx.otherArticles.length == 0))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_ion_icon, {
                          button: "",
                          color: "primary",
                          class: "auto pa-4 o-7",
                          size: "large",
                          icon: _ctx.thumbsDownOutline
                        }, null, 8, ["icon"]),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("favourite.emptyState")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}