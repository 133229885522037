import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { slot: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_check_in_fab = _resolveComponent("check-in-fab")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            _createVNode(_component_check_in_fab, {
              pageName: _ctx.pageName,
              onOpenCheck: _ctx.openCompleteCheckIn,
              onOpenDistress: _ctx.openInDistress
            }, null, 8, ["pageName", "onOpenCheck", "onOpenDistress"])
          ], 512), [
            [_vShow, _ctx.innerWidth < 992]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}