import { Module } from 'vuex';
import { EventEntity } from '@/models/event';
import { ContentService } from '@/services/strapi.service';
import { MeilisearchResult } from './article';
import { firebaseService } from '@/main' ;
import { formatFilter } from '@/services/meil.service';
import { formatEventQuery } from '@/services/qs.service';

interface EventModuleState {
  event: EventEntity;
  events: EventEntity[];
  pageSize: number;
  offset: number;
  hasMore: boolean;
}

const getDefaultState = () => {
  return {
    event: null as any,
    events: [],
    pageSize: 6,
    offset: 0,
    hasMore: true
  }
}

export const eventModule: Module<EventModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getEvent({ commit }, eventId): Promise<string> {
      try {
        const event = await ContentService.getOne<EventEntity>('events', eventId)
        if (event) {
          commit('setEvent', event)
          // also make a stats update.
          firebaseService.updateStats({entity: 'events', id: eventId})
          firebaseService.getAnalytics().logEvent('wf_event_read');
          return ''
        } else {
          commit('setEvent', null)
          return 'could not load event'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get event.';
      }
    },
    async getEvents({ rootState, commit, state }): Promise<string> {
      try {
        commit('setOffset', 0)
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const events = await ContentService.getMany<EventEntity>('events', formatEventQuery(roles, state.pageSize + 1, 0)) // one more than page size.
        if (events) {
          commit('setHasMore', events.length > state.pageSize)
          commit('setEvents', events.slice(0, state.pageSize))
          return ''
        } else {
          commit('setEvents', [])
          return 'could not load events'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get events.';
      }
    },
    async getMoreEvents({ rootState, commit, state }): Promise<string> {
      try {
        if (!state.hasMore) {
          return ''
        }
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const events = await ContentService.getMany<EventEntity>('events', formatEventQuery(roles, state.pageSize + 1, state.offset + state.pageSize))
        commit('setOffset', state.offset + state.pageSize)
        if (events) {
          commit('setHasMore', events.length > state.pageSize)
          commit('addEvents', events.slice(0, state.pageSize))
          return ''
        } else {
          // commit('setArticles', [])
          return 'could not add evenrs'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get articles.';
      }
    },
    async getSearchEvents({ rootState, state, commit }, search): Promise<string> {
      try {
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const data: MeilisearchResult<EventEntity> = await ContentService.runSearch<EventEntity>({
          entity: 'events',
          search: search ?? '',
          offset: 0,
          limit: state.pageSize,
          filter: formatFilter(roles)
        })
        commit('setOffset', 0)
        commit('setHasMore', data.nbHits > (data.hits.length))

        commit('setEvents', data.hits)
        return ''
        } catch (error) {
          return (error instanceof Error) ? error.toString() : 'error on search events.';
        }
    },
    async getMoreSearchEvents({ rootState, state, commit }, search): Promise<string> {
      if (!state.hasMore) {
        return ''
      }
      try {
      commit('setOffset', state.offset + state.pageSize)
      const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
      const data: MeilisearchResult<EventEntity> = await ContentService.runSearch<EventEntity>({
        entity: 'events',
        search,
        offset: state.offset,
        limit: state.pageSize,
        filter: formatFilter(roles)
      })

      commit('setHasMore', data.nbHits > (state.events.length + data.hits.length))
      commit('addEvents', data.hits)
      return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on search more events.';
      }
    }
},
  mutations: {
    setEvent(state, event) {
      state.event = event
    },
    setEvents(state, events) {
      state.events = events
    },
    addEvents(state, events) {
      state.events = [...state.events, ...events];
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setHasMore(state, more) {
      state.hasMore = more;
    },
  }
}
