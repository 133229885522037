
import { defineComponent } from "vue";
import CircleProgress from "vue3-circle-progress";
import { IonRow, IonCol } from "@ionic/vue";
export default defineComponent({
  name: "QuestionHeader",
  components: {
    IonRow,
    IonCol,
    CircleProgress,
  },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    question: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  // setup() {
  //   const answerOptions = ["STRONGLY DISAGREE", "DISAGREE", "NEUTRAL", "AGREE", "STRONGLY AGREE"];
  //   return { answerOptions };
  // },
});
