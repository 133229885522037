import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withKeys as _withKeys, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ion-text-center o-87"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {
      _ctx.onSubmit(_ctx.values);
      _ctx.showErrors = true;
    }, ["prevent"]))
  }, [
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ion_row, {
              key: 0,
              style: {"min-height":"96px"},
              class: "ion-justify-content-center px-4 pb-4 flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_spinner, {
                  style: {"transform":"scale(2)"},
                  class: "auto",
                  name: "crescent",
                  color: "primary"
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t("login.loading")), 1))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ion_row, {
              key: 2,
              class: "ion-justify-content-center px-4 pb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "size-lg": "6",
                  "size-md": "8",
                  size: "10"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { class: "textbox-grey" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("login.loginLabel")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_textarea, {
                          autocomplete: "off",
                          debounce: "500",
                          maxlength: "250",
                          modelValue: _ctx.phrase,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phrase) = $event)),
                          type: "text",
                          name: "phrase",
                          rows: "2",
                          enterkeyhint: "enter",
                          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSubmit(_ctx.values)), ["enter"]))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_ctx.errorTxt || _ctx.phraseError)
                      ? (_openBlock(), _createBlock(_component_input_error, {
                          key: 0,
                          show: _ctx.showErrors || _ctx.errorTxt,
                          msg: _ctx.errorTxt || _ctx.phraseError
                        }, null, 8, ["show", "msg"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              "size-lg": "6",
              "size-md": "8",
              size: "10"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  class: "button-blue",
                  expand: "full",
                  type: "submit",
                  disabled: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("welcome.loginButton")) + "   ", 1),
                    _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}