
import {
  IonButton,
  IonContent,
  IonIcon,
  IonRow,
  IonSpinner,
  IonicSwiper,
} from "@ionic/vue";
import {
  informationCircleOutline,
  chevronBack,
  chevronForward,
} from "ionicons/icons";
import { dateMixin } from "@/mixins/date.format";
import { defineComponent, ref } from "vue";
import WellnessCircle from "@/components/common/WellnessCircle.vue";
import YrpCircleLogo from "@/components/common/YrpCircleLogo.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper-bundle.min.css";
import "@ionic/vue/css/ionic-swiper.css";

SwiperCore.use([Navigation, Pagination, IonicSwiper]);

export default defineComponent({
  name: "LatestCheckInSwiper",
  mixins: [dateMixin],
  components: {
    IonContent,
    IonSpinner,
    IonButton,
    IonIcon,
    IonRow,
    WellnessCircle,
    YrpCircleLogo,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const slides = ref();
    const activeSlide = ref(0);
    const loading = ref(true);

    return {
      activeSlide,
      slides,
      chevronBack,
      chevronForward,
      informationCircleOutline,
      loading,
    };
  },
  computed: {
    myCheckIns(): any {
      return this.$store.state.check.myCheckIns;
    },
  },
  ionViewDidLeave() {
    if (this.slides) {
      this.slides.destroy();
    }
  },
  methods: {
    onSwiper(swiper: any) {
      if (swiper) {
        this.slides = swiper;
        this.activeSlide = swiper.activeIndex;
        swiper.allowTouchMove = false;
      }
    },
    moop(swiper: any) {
      this.activeSlide = swiper.activeIndex;
    },
    nextSlide() {
      this.slides.slideNext(450, false);
    },
    lastSlide() {
      this.slides.slidePrev(450, false);
    },
  },
  async mounted() {
    await this.$timer(3000);
    this.loading = false;
  },
});
