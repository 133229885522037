
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'VideoFrame',
  props: {
    src: { // src examples:
      type: String, // https://player.vimeo.com/video/76979871?quality=
      default: null //  https://www.youtube.com/embed/LDVq-zw32Ac
    }
  },
  setup(props) {
    let videoType;
    let videoId;
    if (props.src.includes('youtube')) {
      videoType = ref('youtube');
    } else if (props.src.includes('vimeo')) {
      videoType = ref('vimeo')
      const rego = new RegExp('[0-9]{8,}')
      videoId = rego.exec(props.src)
    }
    const supported = ref(videoType ? true : false)
    return { videoType, videoId, supported }
  }
})
