import { checkConverter, CheckIn } from '@/models/checkIn';
import { CheckInForm } from '@/models/checkInForm';
import { QuestionResponse } from '@/models/questionResponse';
import { Module } from 'vuex';
import { default as mockCheckIns } from './mock.checkins.json';
import { firebaseService } from '@/main' ;

interface CheckInModuleState {
  myCheckIns: CheckIn[];
  questionResponses: QuestionResponse[];
  checkInForm: CheckInForm | null;
}

const getDefaultState = () => {
  return {
    myCheckIns: [],
    questionResponses: [],
    checkInForm: new CheckInForm(),
  }
}

export const checkModule: Module<CheckInModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    latestCheckIn: state => {
      if (!state.myCheckIns || state.myCheckIns.length == 0) return null
      return state.myCheckIns[0]
    },
    latestCheckInAverage: state => {
      if (!state.myCheckIns || state.myCheckIns.length == 0) return null
        let dividend = 400;
        if (state.myCheckIns[0].biological === 0) {
          dividend -= 100;
        }
        if (state.myCheckIns[0].psychological === 0) {
          dividend -= 100;
        }
        if (state.myCheckIns[0].social === 0) {
          dividend -= 100;
        }
        if (state.myCheckIns[0].spiritual === 0) {
          dividend -= 100;
        }
        const a = (state.myCheckIns[0].biological ?? 0) + (state.myCheckIns[0].psychological ?? 0) + (state.myCheckIns[0].social ?? 0) + (state.myCheckIns[0].spiritual ?? 0)
      return Math.round(((a) / dividend ) * 100) || 100;
    },
    lowestTopic: state => {
      // TODO!!!
      // let out = {topic: 'N/A', value: 100};
      let out = { topic: 'Balance', value: 100 }; // jsut a placeholder.

      if (!state.myCheckIns || state.myCheckIns.length == 0) return out
      const firstCheckIn: any = state.myCheckIns[0];
      for (const property in firstCheckIn) {
        if (
          !('biologicalpsychologicalspiritualsocialcreatedcounted'.includes(property))
        ) {
          if (firstCheckIn[property] != 0 && firstCheckIn[property] < out.value) {
            out = { topic: 
              property[0].toUpperCase() + property.substring(1), value: firstCheckIn[property] }
          }
        }
      }
      return out
    },
    topics: state => {

      const out: any[] = [];
      
      function compare( a: any, b: any ) {
        if ( a.value < b.value ){
          return -1;
        }
        if ( a.value > b.value ){
          return 1;
        }
        return 0;
      }

      if (!state.myCheckIns || state.myCheckIns.length == 0) return out
      const firstCheckIn: any = state.myCheckIns[0];
      for (const property in firstCheckIn) {
        if (
          !('biologicalpsychologicalspiritualsocialcreatedcounted'.includes(property))
        ) {
          out.push(
            { topicName: property[0].toUpperCase() + property.substring(1), value: firstCheckIn[property] }
          )
        }
      }
      out.sort(compare)
      out.forEach((obj, index) => obj.rank = index + 1)
      return out
    },
    lowestCategory: state => {
      let out = { category: 'N/A', value: 100 };
      if (!state.myCheckIns || state.myCheckIns.length == 0) return out
      const firstCheckIn: any = state.myCheckIns[0];
      for (const property in firstCheckIn) {
        if (
          ('biologicalpsychologicalspiritualsocial'.includes(property))
        ) {
          if (firstCheckIn[property] != 0 && firstCheckIn[property] < out.value) {
            out = { category: property[0].toUpperCase() + property.substring(1), value: firstCheckIn[property] }
          }
        }
      }
      return out
    },
    bioPercent: state => {
      return state.checkInForm?.getBio() ?? {}
    },
    psychPercent: state => {
      return state.checkInForm?.getPsych() ?? {}
    },
    socialPercent: state => {
      return state.checkInForm?.getSocial() ?? {}
    },
    spiritPercent: state => {
      return state.checkInForm?.getSpiritual() ?? {}
    },
  },
  actions: {
    async loadCheckIns({ rootState, commit }): Promise<string> {
      try {
        if (process.env.NODE_ENV == 'development') {
          commit('setCheckInsDirectly', mockCheckIns)
          return ''
        }
        if (!rootState.user.myUser || !rootState.user.myUser.uid) return 'no user to load check ins for.';
        const snapshot = await firebaseService.loadCheckIns(rootState.user.myUser.uid);
        commit('setCheckIns', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on check-in load.';
      }
    },
    async loadQuestionResponses({ rootState, commit }): Promise<string> {
      try {
        if (!rootState.user.myUser || !rootState.user.myUser.uid) return 'no user to load responses for.';
        const snapshot = await firebaseService.loadQuestionResponses(rootState.user.myUser.uid);
        commit('setResponses', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on responses load.';
      }
    },
    async submitCheckIn({ rootState, dispatch }, checkIn: CheckIn): Promise<string> {
      try {
        if (!rootState.user.myUser || !rootState.user.myUser.uid) return 'no user to check in.';
        await firebaseService.submitCheckIn(checkConverter.toFirestore(checkIn)); // must be normal object.
        await dispatch('user/loadAllUserData', null, {root: true})
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on check-in submit.';
      }
    },
    async updateForm({ commit }, checkInForm: CheckInForm) {
      commit('setCheckInForm', checkInForm)
    }
  },
  mutations: {
    setCheckIns(state, snapshot) {
      state.myCheckIns = [];
      snapshot.forEach((doc: any) => {
        const c = doc.data();
        // c.id = doc.id // check in has no id field
        state.myCheckIns.push(c);
      });
    },
    setCheckInsDirectly(state, checks) {
      state.myCheckIns = checks
    },
    setResponses(state, snapshot) {
      state.questionResponses = [];
      snapshot.forEach((doc: any) => {
        state.questionResponses.push(doc.data());
      });
    },
    setCheckInForm(state, checkInForm) {
      state.checkInForm = checkInForm
    },
  }
}
