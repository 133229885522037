import { createStore } from 'vuex'
import { checkModule } from './check'
import { userModule } from './user'
import { articleModule } from './article'
import { resourceModule } from './resource'
import { questionModule } from './question'
import { eventModule } from './event'
import { contactModule } from './contact'

export const store = createStore({
  modules: {
      user: userModule,
      contact: contactModule,
      check: checkModule,
      article: articleModule,
      event: eventModule,
      resource: resourceModule,
      question: questionModule
  }
})
