import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import { Storage } from '@ionic/storage';
import { SnackbarPlugin } from 'snackbar-vue';

import router from './router';
import { store } from './store/store';

import globalMethods from './plugins/utils';
import i18n from './plugins/il8n';
import marked from './plugins/marked';
import { snackBarConfig } from './plugins/vue-snack';

// optionally import default styles
import 'snackbar-vue/dist/snackbar-vue.common.css';
import "vue3-circle-progress/dist/circle-progress.css";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';

import './registerServiceWorker'
import { FirebaseService } from './services/firebase.service';
import App from './App.vue'
import { setUpInterceptor } from './services/axios.service';

// available globally.
export const localStorage = new Storage();
localStorage.create();

export const firebaseService = new FirebaseService();
setUpInterceptor();

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md',
    swipeBackEnabled: true,
  })
  .use(router)
  .use(store)
  .use(i18n)
  .use(marked)
  .use(globalMethods)
  .use(SnackbarPlugin, snackBarConfig);

router.isReady().then(() => {
  app.mount('#app');
});