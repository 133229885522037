
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  IonRow,
  IonSpinner
} from "@ionic/vue";
import InputError from "@/components/common/InputError.vue";
import { useForm, useField } from "vee-validate";
import { defineComponent, ref } from "vue";
import { chevronForward } from "ionicons/icons";
export default defineComponent({
  name: "OnboardForm",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "",
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
    IonRow,
    IonSpinner,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: activationcode, errorMessage: codeError } = useField(
      "activationcode",
       (value: any) => {
        if (!value) {
          return "Please enter a valid activation code.";
        }
        return true;
      }
    );
    let t: any;
    return {
      chevronForward,
      activationcode,
      codeError,
      onSubmit,
      showErrors,
      t,
    };
  },
});

