import { DateTime } from 'luxon'

// Date Mixin
export const dateMixin = {
  methods: {
    readableShortDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATE_MED) : '' // Oct 14, 1983
    },
    readableDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATETIME_FULL) : '' //  	October 14, 1983, 1:30 PM EDT
    },
    relativeDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).toRelativeCalendar() ?? '' : ''
    },
    dateFormat(format: string, iso?: string) {
      return iso ? DateTime.fromISO(iso).toFormat(format) ?? iso : iso
    }
  }
}
