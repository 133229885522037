
import {
  IonContent,
  IonPage,
  IonGrid,
  IonHeader,
  IonCol,
  IonRow,
  IonButtons,
  IonRefresherContent,
  IonRefresher,
  IonToolbar,
  modalController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  chevronForward,
  accessibilityOutline,
  apertureOutline,
  chatbubblesOutline,
  bulbOutline,
} from "ionicons/icons";

import { UserDetails } from "@/models/userDetails";
import SkeletonFeed from "@/components/common/SkeletonFeed.vue";
import LatestCheckInSwiper from "@/components/home/dashboard/LatestCheckInSwiper.vue";
import TrendBlock from "@/components/home/dashboard/TrendBlock.vue";
import ArticlesFeed from "@/components/home/dashboard/ArticlesFeed.vue";
import CheckInModal from "@/components/home/checkin/CheckInModal.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import WalnutModalVue from "@/components/home/wizard/WalnutModal.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import { Region } from "@/models/region";
import FavModalButton from "@/components/common/FavModalButton.vue";
import SidebarBox from "@/components/common/SidebarBox.vue";
import UpcomingEventsBox from "@/components/common/UpcomingEventsBox.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    BackButton,
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonRefresherContent,
    IonRefresher,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ArticlesFeed,
    SkeletonFeed,
    LatestCheckInSwiper,
    TrendBlock,
    ResourcesInArea,
    NotificationBell,
    HelpButton,
    FavModalButton,
    SidebarBox,
    UpcomingEventsBox
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    return {
      content,
      chevronForward,
      accessibilityOutline,
      apertureOutline,
      chatbubblesOutline,
      bulbOutline,
      loading,
    };
  },
  computed: {
    myUser(): any {
      return this.$store.state.user.myUser;
    },
    myDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    trendsLength(): number {
      return this.$store.state.check.myCheckIns.length;
    },
    getScrollTop(): boolean {
      return this.$store.state.user.scrollTop;
    },
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    }
  },
  async ionViewDidEnter() {
    await this.getUserDetails();
    await this.loadData();
    this.loading = false;
  },
  methods: {
    async getUserDetails() {
      let breaker = false;
      let rounds = 0;
      const maxRounds = 3;
      while(!breaker) {
        await this.$timer(800);
        rounds++;
        breaker = (rounds > maxRounds) || !!this.myDetails;
      }
      if (!this.myDetails || !this.myDetails.wizardCompleted) {
        this.openDialog();
      }
    },
    async openDialog() {
      const modal = await modalController.create({
        mode: "ios",
        backdropDismiss: true,
        swipeToClose: true,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        component: WalnutModalVue,
      });
      modal.present();
      modal.onDidDismiss().then(() => {
        this.openCompleteCheckIn();
      });
    },
    async openCompleteCheckIn() {
      const modal = await modalController.create({
        mode: "ios",
        backdropDismiss: true,
        swipeToClose: true,
        component: CheckInModal,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
      });
      modal.present();
    },
    async loadData(event?: any, more?: boolean) {
      // these are articles that are anything but the recommended
      await this.$store.dispatch(`article/${more ? "getMoreRecom" : "getRecom"}`);
      if (!more) {
        await this.$store.dispatch(`article/getShellArticle`);  
      }
      if (event) {
        event.target!.complete();
      }
    },
    scrollTop() {
      // this method exists on the ion content entity!!
      this.content.$el.scrollToTop(300);
    },
  },
  watch: {
    getScrollTop(val) {
      if (val) {
        this.content.$el.scrollToTop(300);
      }
    },
  },
});
