
import {
  IonContent,
  IonFabButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonLabel,
  IonMenu,
  IonHeader,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import {
  menuOutline,
  checkmarkCircleOutline,
  settingsOutline,
  logOutOutline,
  peopleOutline,
  searchOutline,
  calendarOutline,
  helpBuoyOutline,
} from "ionicons/icons";
import { defineComponent } from "vue";
import CircleProgress from "vue3-circle-progress";
import CheckInModal from "@/components/home/checkin/CheckInModal.vue";
import YrpCircleLogo from "../common/YrpCircleLogo.vue";
import { UserDetails } from "@/models/userDetails";
import DistressModal from './DistressModal.vue';

export default defineComponent({
  name: "MainMenu",
  components: {
    IonContent,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonToolbar,
    YrpCircleLogo,
    CircleProgress,
  },
  setup() {
    return { menuOutline, checkmarkCircleOutline, settingsOutline, logOutOutline, peopleOutline, searchOutline, calendarOutline, helpBuoyOutline};
  },
  computed: {
    getUser() {
      return this.$store.state.user.myUser;
    },
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    latestCheckIn() {
      return this.$store.getters["check/latestCheckIn"];
    },
    isHome() {
      return this.$route.path.includes('home')
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout').then(()=> {
        this.$router.push('home/dashboard');
      })
    },
    async openCompleteCheckIn() {
      this.$router.push("/home/dashboard");
      const modal = await modalController.create({
        mode: 'ios',
        component: CheckInModal,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
    async openInDistress() {
      const modal = await modalController.create({
        mode: 'ios',
        component: DistressModal,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
  },
});
