import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createBlock(_component_ion_icon, {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
      _ctx.isFav(_ctx.articleId) ? _ctx.unFavArticle(_ctx.articleId) : _ctx.favArticle(_ctx.articleId)
    ), ["stop"])),
    button: "",
    class: "auto my-pointer",
    icon: (_ctx.localChange != null ? _ctx.localChange : _ctx.isFav(_ctx.articleId)) ? _ctx.heart : _ctx.heartOutline,
    size: "large"
  }, null, 8, ["icon"]))
}