import { Question } from '@/models/question';
import { Module } from 'vuex';
import { ContentService } from '@/services/strapi.service';

interface QuestionModuleState {
  checkInQuestions: Question[];
}

const getDefaultState = () => {
  return {
    checkInQuestions: []
  }
}

export const questionModule: Module<QuestionModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    bioQuestions: state => {
      if (!state.checkInQuestions) return []
      return state.checkInQuestions.filter(question => question.category ? question.category.name == 'Biological' : false)
    },
    bioTopics: state => {
      if (!state.checkInQuestions) return []
      const out: any[] = [];
      state.checkInQuestions.forEach(question =>  {
        if (question.category && question.category.name == 'Biological') {
          if (question.topic) {
           out.push((question.topic.name ?? '').toLowerCase())
          }
        }
      })
      // remove duplicates
      return Array.from(new Set(out))
    },
    psychTopics: state => {
      if (!state.checkInQuestions) return []
      const out: any[] = [];
      state.checkInQuestions.forEach(question =>  {
        if (question.category && question.category.name == 'Psychological') {
          if (question.topic) {
           out.push((question.topic.name ?? '').toLowerCase())
          }
        }
      })
      // remove duplicates
      return Array.from(new Set(out))
    },
    socialTopics: state => {
      if (!state.checkInQuestions) return []
      const out: any[] = [];
      state.checkInQuestions.forEach(question =>  {
        if (question.category && question.category.name == 'Social') {
          if (question.topic) {
           out.push((question.topic.name ?? '').toLowerCase())
          }
        }
      })
      // remove duplicates
      return Array.from(new Set(out))
    },
    spiritualTopics: state => {
      if (!state.checkInQuestions) return []
      const out: any[] = [];
      state.checkInQuestions.forEach(question =>  {
        if (question.category && question.category.name == 'Spiritual') {
          if (question.topic) {
           out.push((question.topic.name ?? '').toLowerCase())
          }
        }
      })
      // remove duplicates
      return Array.from(new Set(out))
    },
    psychQuestions: state => {
      if (!state.checkInQuestions) return []
      return state.checkInQuestions.filter(question => question.category ?question.category.name == 'Psychological' : false)
    },
    socialQuestions: state => {
      if (!state.checkInQuestions) return []
      return state.checkInQuestions.filter(question => question.category ? question.category.name == 'Social' : false)
    },
    spiritQuestions: state => {
      if (!state.checkInQuestions) return []
      return state.checkInQuestions.filter(question => question.category ? question.category.name == 'Spiritual' : false)
    },
  },
  actions: {
    async getCheckInQuestions({commit}): Promise<string> {
      try {
        const checkInQuestions = await ContentService.getMany<Question>('questions');
        await commit('setCheckInQuestions', checkInQuestions)
        return ''
      } catch (error) {
        // TODO For dev.
        commit('setCheckInQuestions', [])
        return (error instanceof Error) ? error.toString() : 'error on questions load.';
      }
    },
  },
  mutations: {
    setCheckInQuestions(state, checkInQuestions) {
      state.checkInQuestions = checkInQuestions
    },
  }
}