
import { Article } from "@/models/article";
import {
  IonContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import ArticleCard from "../home/dashboard/ArticleCard.vue";
import CloseButton from './../common/CloseButton.vue'
import { thumbsDownOutline } from "ionicons/icons";

export default defineComponent({
  name: "FavModal",
  components: {
    IonContent,
    IonGrid,
    IonIcon,
    IonRow,
    IonCol,
    CloseButton,
    ArticleCard
  },
  setup() {
    const loading = ref(true);
    return { thumbsDownOutline, loading };
  },
  computed: {
    otherArticles(): Article[] {
      return this.$store.state.article.otherArticles
    }
  },
  async mounted() {
    this.$store.dispatch("article/loadFavAsOtherArticles");
    await this.$timer(2000);
    this.loading = false;
  },
  methods: {
    close() {
      modalController.dismiss();
    }
  },
});
