
import { defineComponent } from 'vue'
import {
    IonCard,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
    IonIcon,
    IonRippleEffect
} from "@ionic/vue";
import { videocam } from "ionicons/icons";
import SplashPlaceholder from '@/components/common/SplashPlaceholder.vue';
import FavouriteIcon from '@/components/common/FavouriteIcon.vue';
import { colorMixin } from "@/mixins/get.color";

export default defineComponent({
  name: 'ArticleCard',
  mixins: [colorMixin],
  components: {
    IonCard,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
    IonIcon,
    IonRippleEffect,
    SplashPlaceholder,
    FavouriteIcon,
  },
  props: {
    article: {
      type: Object,
      default: null
    }
  },
  setup() {
    return { videocam }
  }
})
