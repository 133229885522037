import { CheckIn } from "./checkIn";
import { QuestionResponse } from "./questionResponse";



export class CheckInForm {
  constructor(
    public inProgress: number = 0, // last slide they were on
    public responses: QuestionResponse[] = [],
    public skipBio: boolean = false,
    public skipPsych: boolean = false,
    public skipSocial: boolean = false,
    public skipSpirit: boolean = false,
  ) {
    this.responses = new Array<QuestionResponse>(16)
  }

  // how to identify question categories?
  public getBio(): number {
    let total = 0
    this.responses.forEach((r: any) => {
      if (r.category && r.category == 'Biological') {
        total += r.response ?? 0
      }
    })
    return Math.round(((total) / 400) * 100);
  }
  public getPsych(): number {
    let total = 0
    this.responses.forEach((r: any) => {
      if (r.category && r.category == 'Psychological') {
        total += r.response ?? 0
      }
    })
    return Math.round(((total) / 400) * 100);
  }
  public getSocial(): number {
    let total = 0
    this.responses.forEach((r: any) => {
      if (r.category && r.category == 'Social') {
        total += r.response ?? 0
      }
    })
    return Math.round(((total) / 400) * 100);
  }
  public getSpiritual(): number {
    let total = 0
    this.responses.forEach((r: any) => { // QuestionResponse?
      if (r.category && r.category == 'Spiritual') {
        total += r.response ?? 0
      }
    })
    return Math.round(((total) / 400) * 100);
  }

  public toCheckIn(): CheckIn {
    return new CheckIn(
      this.skipBio ? 0 : this.getBio(),
      // { seconds: new Date().getTime() / 1000 },
      new Date(),
      false,
      this.skipPsych ? 0 : this.getPsych(),
      this.responses,
      this.skipSocial ? 0 : this.getSocial(),
      this.skipSpirit ? 0 : this.getSpiritual()
    )
  }
}