import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2750545c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-frame pa-2"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["data-clip-info", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.supported)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.videoType == 'youtube')
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              width: "560",
              height: "315",
              src: _ctx.src,
              style: {"--aspect-ratio":"315 / 560"},
              frameborder: "0",
              allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: ""
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.videoType == 'vimeo')
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              width: "560",
              height: "315",
              title: "vimeo-player",
              "data-clip-info": _ctx.videoId,
              src: _ctx.src,
              style: {"--aspect-ratio":"315 / 560"},
              allow: "autoplay; fullscreen",
              allowfullscreen: "",
              mozallowfullscreen: "",
              webkitallowfullscreen: "",
              frameborder: "0"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}