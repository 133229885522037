import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","height":"100%"} }
const _hoisted_2 = { class: "ion-text-center pa-4" }
const _hoisted_3 = { class: "headline-5" }
const _hoisted_4 = { class: "pt-1 ion-padding-horizontal" }
const _hoisted_5 = { class: "headline-5" }
const _hoisted_6 = { class: "body-2 o-87" }
const _hoisted_7 = { class: "pb-4 headline-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yrp_logo = _resolveComponent("yrp-logo")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "4",
                      "size-md": "6",
                      size: "10"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_yrp_logo)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "6",
                      "size-md": "8",
                      size: "10"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t("success.message")), 1)
                        ]),
                        _createVNode(_component_ion_item, { class: "textbox-grey mt-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_textarea, {
                              autocomplete: "off",
                              name: "completedphrase",
                              type: "text",
                              value: _ctx.getPhrase ?? '',
                              readonly: "",
                              style: {"font-weight":"bold"},
                              "auto-grow": ""
                            }, null, 8, ["value"]),
                            _createVNode(_component_ion_button, {
                              class: "mt-4",
                              fill: "clear",
                              slot: "end",
                              color: "primary",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.copy(_ctx.getPhrase); _ctx.copied = true})
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.copyOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_ion_text, { color: "danger" }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", {
                                class: "my-underline my-pointer",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.copy(_ctx.getPhrase); _ctx.copied = true})
                              }, _toDisplayString(_ctx.$t("success.copyText")), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4 mt-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "6",
                      "size-md": "8",
                      size: "10",
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t("success.hint")), 1),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("success.hintText")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "6",
                      "size-md": "8",
                      size: "10",
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, {
                          color: _ctx.copied ? 'dark' : 'danger'
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("success.instructions")), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createVNode(_component_ion_button, {
                          color: "primary",
                          expand: "full",
                          class: "mt-4",
                          disabled: !_ctx.copied,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/home')))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("success.continueButton")) + "   ", 1),
                            _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}