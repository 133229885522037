import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "auto",
    style: _normalizeStyle({'height': _ctx.height})
  }, [
    _createElementVNode("img", {
      style: {"max-height":"100%"},
      src: '/../assets/' + _ctx.cat  + '.png',
      alt: "splash"
    }, null, 8, _hoisted_1)
  ], 4))
}