import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "auto",
  style: {"position":"relative","height":"128px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress = _resolveComponent("circle-progress")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "ion-justify-content-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, {
        size: "12",
        class: "flex"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_circle_progress, {
              "empty-color": "#F5F5F5",
              "fill-color": _ctx.color,
              size: 128,
              percent: _ctx.progress
            }, null, 8, ["fill-color", "percent"]),
            _createElementVNode("h5", {
              class: "headline-5",
              style: _normalizeStyle([{"position":"relative","bottom":"96px","height":"0px"}, { color: _ctx.color }])
            }, _toDisplayString(_ctx.title), 5)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, { size: "12" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "headline-5",
            style: _normalizeStyle({ color: _ctx.color })
          }, _toDisplayString(_ctx.question) + " / 4", 5)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}