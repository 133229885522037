
import { defineComponent } from "vue";
import { IonButton, IonIcon, } from "@ionic/vue";
import { informationCircleOutline } from "ionicons/icons";
import { UserDetails } from "@/models/userDetails";

export default defineComponent({
  name: "InfoButton",
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    category: {
      type: String,
      default: null
    }
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
  },
  setup() {
    return { informationCircleOutline };
  },
});
