
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
import YrpLogo from "@/components/common/YrpLogo.vue";
import LoginForm from  "@/components/onboard/LoginForm.vue";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    YrpLogo,
    LoginForm
  },
  setup() {
    const loading = ref(false);
    const errorTxt = ref("");
    const snack = useSnackbarPlugin();
    return {
      loading,
      errorTxt,
      snack
    };
  },
  ionViewDidLeave() {
    this.loading = false;
  },
  methods: {
    formatPhrase(phrase: string) {
      return phrase.trim().toLowerCase()
    },
    async onSubmit(values: { phrase: string }) {
      this.loading = true;
      const errorMsg = await this.$store.dispatch("user/login", this.formatPhrase(values.phrase));
      await this.$timer(1000);
      if (errorMsg) {
        this.errorTxt = errorMsg;
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : this.$t("login.loginFail"),
          time: 3000,
          close: true,
        });
        this.loading = false;
      } else {
        this.$router.push("/home/dashboard");
      }
      await this.$timer(2000);
      this.errorTxt = '';
    },
  },
});
