
import { defineComponent } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { chevronBack } from "ionicons/icons";

export default defineComponent({
  name: "BackButton",
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return { chevronBack };
  },
});
