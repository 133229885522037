// id_in=1&id_in=3&id_in=4&id_in=5&id_in
export function whereOr(field: string, values: any[]): string {
  let output = '';
  values.forEach((v, index )=> {
    output += `${field}_in=${v}`
    if (index < values.length - 1) {
      output += '&'
    }
  })
  return output
}