import axios from 'axios'
import { MeilisearchResult } from '@/store/article';
import { Article } from '@/models/article';

interface SearchParam {
  entity: string;
  search: string;
  offset: number;
  limit: number;
  filter: string;
}

export class ContentService {

  static async getOne<T>(entity: string, entityId: string): Promise<T> {
    try {
      const { data } = await axios.get(`/${entity}/` + (entityId ? entityId : ''))
      if (data) {
        return data as T
      } else {
        throw Error(`Failed to load 1 ${entity}`)
      }
    } catch (err) {
      throw Error(`Failed to load 1 ${entity} : ${err}`)
    }
  }

  static async getMany<T>(entity: string, query?: string): Promise<T[]> {
    try {
      const { data } = await axios.get(`/${entity}` + (query ? query : ''));
      if (data) {
        return data.map((a: any) => a as T)
      } else {
        throw Error(`Failed to load ${entity}`)
      }
    } catch (err) {
      throw Error(`Failed to load ${entity} : ${err}`)
    }
  }


  static async getRecomm(body: object): Promise<Article[]> {
    try {
      const { data } = await axios.post(`/articles/recomm`, body);
      if (data) {
        return data.map((a: any) => a as Article)
      } else {
        throw Error(`Failed to load recomm`)
      }
    } catch (err) {
      throw Error(`Failed to load recomm : ${err}`)
    }
  }

  static async runSearch<T>(search: SearchParam): Promise<MeilisearchResult<T>> {
    try {
      // the method is under the articles api, works for contacts, resources and events too.
      const { data } = await axios.post(`/articles/search`, search);
      if (data) {
        return data
      } else {
        throw Error(`Failed to search ${search.entity}`)
      }
    } catch (err) {
      throw Error(`Failed to search ${search.entity} : ${err}`)
    }
  }
}