import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ma-0 o-7",
  style: {"font-size":"54px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress = _resolveComponent("circle-progress")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "wellness",
    style: _normalizeStyle({
      transform: `scale(${_ctx.scale})`,
      position: 'relative',
      margin: 'auto',
    })
  }, [
    _createVNode(_component_circle_progress, {
      "empty-color": "#c6d6dc",
      "fill-color": _ctx.color,
      size: _ctx.size,
      percent: _ctx.one,
      style: {"position":"absolute"}
    }, null, 8, ["fill-color", "size", "percent"]),
    _createElementVNode("div", {
      style: _normalizeStyle([{"position":"absolute","width":"100%","height":"100%","display":"flex","align-items":"center","justify-content":"center"}, {'top': _ctx.size / 2 +'px'}])
    }, [
      _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.one), 1)
    ], 4)
  ], 4))
}