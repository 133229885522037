
import { defineComponent, ref } from "vue";
import { IonChip, IonLabel, popoverController } from "@ionic/vue";
import { useSnackbarPlugin } from "snackbar-vue";
import { Region } from "@/models/region";
import RegionPopover from "./RegionPopover.vue";
import { Geolocation } from "@capacitor/geolocation";

export default defineComponent({
  name: "RegionSelector",
  components: {
    IonChip,
    IonLabel,
  },
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    trigger(val) {
      if (val) {
        if (this.myRegionId == null || this.myRegionId == undefined) {
          this.getPosition();
        }
      }
    },
  },
  setup() {
    const snack = useSnackbarPlugin();
    const centerMarker = ref({ lat: 43.86681, lng: -79.7252042 });
    return { snack, centerMarker };
  },
  computed: {
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
  },
  methods: {
    async getPosition() {
      try {
        const position = await Geolocation.getCurrentPosition();
        if (position) {
          this.setPosition(position);
        } else {
          throw Error();
        }
      } catch (err) {
        // if no region selected or declined,
        this.$store.dispatch("user/saveToStorage", {
          key: "myRegionId",
          value: 0,
        });
        this.snack.show({
          position: "bottom",
          text: "Geolocation is not available.",
          time: 3000,
          close: true,
        });
      }
    },
    setPosition(position: any) {
      if (position) {
        this.centerMarker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // save the region id!!
        this.findClosestRegion(this.centerMarker);
      }
    },
    findClosestRegion(point: { lat: number; lng: number }) {
      // d = √[(x2 − x1)2 + (y2 − y1)2]
      let distanceBetween = 1;
      let cR = null as any; // closestRegion

      this.regions.forEach((r: any) => {
        const d = Math.sqrt(
          Math.pow(point.lat - r.lat, 2) + Math.pow(point.lng - r.lng, 2)
        );
        if (d < distanceBetween) {
          distanceBetween = d;
          cR = r;
        }
      });
      if (cR) {
        // save region.
        this.$store.dispatch("user/saveToStorage", {
          key: "myRegionId",
          value: cR.id,
        });
      }
    },
    async openPopover(ev: Event) {
      const popover = await popoverController.create({
        component: RegionPopover,
        event: ev,
        translucent: true,
      });
      await popover.present();
    },
  },
});
