
import { defineComponent } from 'vue'
import { IonList, IonLabel, IonItem, IonSkeletonText, IonThumbnail } from "@ionic/vue";
export default defineComponent({
  components: {
   IonList, IonLabel, IonItem, IonSkeletonText, IonThumbnail 
  },
  setup() {
    const mocks =[1, 2, 3, 4, 5, 6]
    return { mocks }
  }
})
