
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { UserDetails } from "@/models/userDetails";
import { dateMixin } from "@/mixins/date.format";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";

export default defineComponent({
  name: "MySettings",
  mixins: [dateMixin],
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    NotificationBell,
    BackButton
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    }
  },
  setup() {
    const loading = ref(false);
    return { loading };
  },
  async mounted() {
    await this.$timer(1000)
    if (!this.userDetails) {
      this.$router.push("/home/dashboard");
    }
  },
  methods: {
  },
});
