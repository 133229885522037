
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { Contact } from "@/models/contact";
import { colorMixin } from "@/mixins/get.color";
import RegionSelector from "@/components/home/wizard/RegionSelector.vue";
import { Region } from "@/models/region";
import CloseButton from "../common/CloseButton.vue";
import ContactListItem from "./contacts/ContactListItem.vue";

export default defineComponent({
  name: "HelpNowModal",
  mixins: [colorMixin],
  components: {
    IonButton,
    IonContent,
    IonCol,
    IonGrid,
    IonList,
    IonItem,
    IonLabel,
    IonRow,
    IonSpinner,
    RegionSelector,
    CloseButton,
    ContactListItem,
  },
  setup() {
    const loading = ref(true);
    return { loading };
  },
  async mounted() {
    await this.$timer(1500);
    this.loadContacts();
  },
  methods: {
    async loadContacts() {
      if (this.myRegion) {
        const query: any = {'region.name' : this.myRegion.name}
        const categoryName = this.lowestCategory ? this.lowestCategory.category : '';
        if (categoryName) {
          query['category.name'] = categoryName
        }
        await this.$store.dispatch( "contact/getRecommendedContacts", query);
      }
      this.loading = false;
    },
    closeAndDismiss() {
      modalController.dismiss();
      this.$store.dispatch("user/updateUserDetails", { dismissHeader: true });
    },
    close() {
      modalController.dismiss();
    },
  },
  computed: {
    lowestTopic(): { topic: string; value: number } {
      return this.$store.getters["check/lowestTopic"];
    },
    lowestCategory(): { category: string; value: number } {
      return this.$store.getters["check/lowestCategory"];
    },
    contacts(): Contact[] {
      return this.$store.state.contact.recomContacts;
    },
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
  },
});
