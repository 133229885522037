
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonicSwiper,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { chevronBack, checkmarkCircle } from "ionicons/icons";
import { useSnackbarPlugin } from "snackbar-vue";
import CheckInFormVue from "./CheckInForm.vue";
import QuestionRow from "./QuestionRow.vue";
import { Question } from "@/models/question";
import { CheckInForm } from "@/models/checkInForm";
import QuestionHeader from "./QuestionHeader.vue";
import CheckInSuccess from "./CheckInSuccess.vue";
import CheckInSkip from "./CheckInSkip.vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import CloseButton from "@/components/common/CloseButton.vue";
import { Region } from "@/models/region";

import "swiper/swiper-bundle.min.css";
import "@ionic/vue/css/ionic-swiper.css";

SwiperCore.use([Navigation, Pagination, IonicSwiper]);

/**
 * SO plan is there is the list of questions
 * we want them on their own slides
 * at the end we want to submit a common thing
 */

export default defineComponent({
  name: "CheckInModal",
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonIcon,
    IonRow,
    CheckInFormVue,
    QuestionRow,
    QuestionHeader,
    CheckInSuccess,
    CheckInSkip,
    Swiper,
    SwiperSlide,
    CloseButton,
  },
  setup() {
    const localCheckInForm = ref(new CheckInForm());
    const loading = ref(false);
    const errorTxt = ref("");
    const activeSlide = ref(0);
    const prevSlide = ref(0);
    const init = ref(false);
    const t = ref(null as any);
    const complete = ref(false);
    const snack = useSnackbarPlugin();
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.

    const slides = ref();

    return {
      activeSlide,
      prevSlide,
      slides,
      chevronBack,
      checkmarkCircle,
      loading,
      errorTxt,
      snack,
      localCheckInForm,
      init,
      t,
      complete,
    };
  },
  computed: {
    checkInForm(): CheckInForm {
      return this.$store.state.check.checkInForm;
    },
    bioQuestions(): Question[] {
      return this.$store.getters["question/bioQuestions"];
    },
    psychQuestions(): Question[] {
      return this.$store.getters["question/psychQuestions"];
    },
    socialQuestions(): Question[] {
      return this.$store.getters["question/socialQuestions"];
    },
    spiritQuestions(): Question[] {
      return this.$store.getters["question/spiritQuestions"];
    },
    bioPercent(): number {
      return this.$store.getters["check/bioPercent"];
    },
    psychPercent(): number {
      return this.$store.getters["check/psychPercent"];
    },
    socialPercent(): number {
      return this.$store.getters["check/socialPercent"];
    },
    spiritPercent(): number {
      return this.$store.getters["check/spiritPercent"];
    },
    questionsAnswered() {
      let total = 0;
      if (this.localCheckInForm) {
        this.localCheckInForm.responses.forEach((r) => {
          if (r.response) {
            total++;
          }
        });
      }
      return total;
    },
    canHitNext() {
      // 2,3,4,5
      if (this.activeSlide > 1 && this.activeSlide < 6) {
        if(!this.localCheckInForm.responses[this.activeSlide - 2]) {
          return false
        }
      // 7,8,9,10
      } else if (this.activeSlide > 6 && this.activeSlide < 11) {
        if(!this.localCheckInForm.responses[this.activeSlide - 3]) {
          return false
        }
      // 12,13,14,15
      } else if (this.activeSlide > 11 && this.activeSlide < 16) {
        if(!this.localCheckInForm.responses[this.activeSlide - 4]) {
          return false
        }
        // 17,18,19,20
      } else if (this.activeSlide > 16 && this.activeSlide < 21) {
        if(!this.localCheckInForm.responses[this.activeSlide - 5]) {
          return false
        }
      }
      return true
    },
    lowestTopic(): {topic: string; value: number} {
      return this.$store.getters["check/lowestTopic"];
    },
    lowestCategory(): {category: string; value: number} {
      return this.$store.getters["check/lowestCategory"];
    },
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
  },
  watch: {
    localCheckInForm: {
      handler(val) {
        this.setCheckInForm();
      },
      deep: true,
    },
  },
  ionViewDidLeave() {
    if (this.slides) {
      this.slides.destroy();
    }
  },
  async mounted() {
    if (this.checkInForm) {
      this.localCheckInForm = this.checkInForm;
      if (this.localCheckInForm.inProgress) {
      //  this.slider2.$el.slideTo(this.localCheckInForm.inProgress);
      }
    }
    await this.$timer(500);
    this.init = true;
  },
  methods: {
    onSwiper(swiper: any) {
      this.slides = swiper;
      this.activeSlide = swiper.activeIndex;
      swiper.allowTouchMove = false;
    },
    moop(swiper: any) {
      this.activeSlide = swiper.activeIndex;
    },
    async setCheckInForm() {
      if (this.t) {
        clearTimeout(this.t);
        this.t = 0;
        this.t = setTimeout(() => {
          this.t = 0;
          this.$store.dispatch("check/updateForm", this.localCheckInForm);
        }, 500);
        return;
      }
      this.t = setTimeout(() => {
        this.t = 0;
        this.$store.dispatch("check/updateForm", this.localCheckInForm);
      }, 500);
    },
    closeMe() {
      modalController.dismiss();
    },
    closeWithData() {
      modalController.dismiss(true);
    },
    check(category: string) {
      let total = 0;
      if (this.localCheckInForm) {
        this.localCheckInForm.responses.forEach((r) => {
          if (r.category == category) {
            total++;
          }
        });
      }
      return total;
    },
    async delayedNextSlide() {
      this.loading = true;
      await this.$timer(300);
      this.slides.slideNext(450, false);
      this.loading = false;
    },
    // instead of next slide, lets have a disableNext computed
    async nextSlide() {
      this.slides.slideNext(450, false);
    },
    lastSlide() {
      if (this.activeSlide == 1) {
        // dont remember.
        return;
      } else if (this.activeSlide <= 6) {
        if (this.check("Biological")) {
          this.slides.slidePrev(450, false);
        } else {
          this.slides.slideTo(1, 450);
        }
        // we are in bio, any bio answers? if no, skip
      } else if (this.activeSlide <= 11) {
        if (this.check("Psychological")) {
          this.slides.slidePrev(450, false);
        } else {
          this.slides.slideTo(6, 450);
        }
      } else if (this.activeSlide <= 16) {
        if (this.check("Social")) {
          this.slides.slidePrev(450, false);
        } else {
          this.slides.slideTo(11, 450);
        }
      } else if (this.activeSlide <= 21) {
        if (this.check("Spiritual")) {
          this.slides.slidePrev(450, false);
        } else {
          this.slides.slideTo(16, 450);
        }
      }
    },
    skipSection() {
      if (this.activeSlide < 2) {
        this.slides.slideTo(6, 450);
      } else if (this.activeSlide < 7) {
        this.slides.slideTo(11, 450);
      } else if (this.activeSlide < 12) {
        this.slides.slideTo(16, 450);
      } else if (this.activeSlide < 23) {
        this.slides.slideTo(21, 450);
      }
    },
    async onSubmit() {
      if (this.questionsAnswered < 4) {
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : "please complete at least one category.",
          time: 3000,
          close: true,
        });
        return
      }
      this.loading = true;
      const errorMsg = await this.$store.dispatch(
        "check/submitCheckIn",
        this.localCheckInForm.toCheckIn()
      );
      if (errorMsg) {
        this.errorTxt = errorMsg;
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : "form submission failed.",
          time: 3000,
          close: true,
        });
        console.log(this.errorTxt);
        this.loading = false;
      } else {
        this.loading = false;
        this.complete = true;
        this.snack.show({
          position: "bottom",
          text: "Success. check in complete.",
          time: 2500,
          close: true,
        });
        this.localCheckInForm = new CheckInForm(); // clear form
        this.$store.dispatch("check/updateForm", new CheckInForm());
      }
      await this.$timer(2000);
      if (this.complete) {
        this.loadRelatedData();
      }
      this.errorTxt = "";
    },
    async loadRelatedData() {
      await this.$store.dispatch(`article/getRecom`);
      this.$store.dispatch(`article/getShellArticle`);
      await this.$store.dispatch("user/clearLocalNotifications");
      this.$store.dispatch("user/checkAndSetLocalNotifications");
    }
  },
});
