
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
import WellnessCircle from "@/components/common/WellnessCircle.vue";
export default defineComponent({
  name: "CheckInSuccess",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    WellnessCircle
  },
  emits: ["close"],
  computed: {
    latestCheckIn(): any {
      return this.$store.getters["check/latestCheckIn"];
    },
    lowestTopic(): {topic: string; value: number} {
      return this.$store.getters["check/lowestTopic"];
    }
  },
});
