import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return _withDirectives((_openBlock(), _createBlock(_component_ion_button, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/home/info?category=${this.category}`)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        class: "o-87",
        size: "large",
        button: "",
        icon: _ctx.informationCircleOutline
      }, null, 8, ["icon"])
    ]),
    _: 1
  }, 512)), [
    [_vShow, _ctx.userDetails]
  ])
}