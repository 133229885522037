
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonChip,
  IonContent,
  IonGrid,
  IonList,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { UserDetails } from "@/models/userDetails";
import { dateMixin } from "@/mixins/date.format";
import { useSnackbarPlugin } from "snackbar-vue";
import ContactGoogleMap from "@/components/common/ContactGoogleMap.vue";
import { Provider } from "@/models/provider";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import SidebarBox from "@/components/common/SidebarBox.vue";
import UpcomingEventsBox from "@/components/common/UpcomingEventsBox.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";


export default defineComponent({
  name: "ContactPage",
  mixins: [dateMixin],
  components: {
    BackButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonChip,
    IonContent,
    IonGrid,
    IonList,
    IonItem,
    IonImg,
    IonLabel,
    IonPage,
    IonRow,
    ContactGoogleMap,
    NotificationBell,
    HelpButton,
    SidebarBox,
    UpcomingEventsBox,
    OtherArticlesBox
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    contact(): any {
      return this.$store.state.contact.contact;
    },
    providers(): any {
      return this.$store.state.contact.providers;
    },
    getMarkers(): any {
      return this.contact
        ? [
            {
              position: { lat: this.contact.lat, lng: this.contact.lng },
              icon: { pointX: 24, pointY: 56, url: this.getPin(this.contact.category ? this.contact.category.name : '') },
              label: this.contact.name,
              title: this.contact.name,
            },
          ]
        : [];
    },
    getProvider(): any {
      if (this.contact && this.providers) {
        return this.providers.find(
          (p: Provider) => p.id == this.contact.category.provider
        );
      }
      return null;
    },
    innerWidth() {
      return this.$store.state.user.innerWidth;
    },
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    const snack = useSnackbarPlugin();
    const contactId = ref("");
    return { content, loading, contactId, snack };
  },
  methods: {
    getColor(category: string) {
      switch (category.toLowerCase()) {
        case "biological":
          return "153, 202, 60";
        case "psychological":
          return "88, 196, 235";
        case "social":
          return "244, 123, 75";
        case "spiritual":
          return "154, 87, 163";
        default:
          return "255, 255, 255";
      }
    },
    getPin(category: string) {
      switch (category.toLowerCase()) {
        case "biological":
          return "/../assets/pin_bio.png";
        case "psychological":
          return "/../assets/pin_psych.png";
        case "social":
          return "/../assets/pin_social.png";
        case "spiritual":
          return "/../assets/pin_spirit.png";
        default:
          return "/../assets/pin_psych.png";
      }
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
  },
  async ionViewWillEnter() {
    const contactId = this.$route.params ? this.$route.params.id : null;
    if ((contactId && !this.contact) || this.contact.id != contactId) {
      this.$store.dispatch("contact/getContact", contactId);
    }
    await this.$timer(1000);
    this.loading = false;
  }
});
