import { Module } from 'vuex'
import { Resource } from '@/models/resource';
import { ContentService } from '@/services/strapi.service';
import { MeilisearchResult } from './article';
import { formatFilter } from '@/services/meil.service';

interface ResourceModuleState {
  resources: Resource[];
  pageSize: number;
  offset: number;
  hasMore: boolean;
  
}

const getDefaultState = () => {
  return {
    resources: [],
    pageSize: 20,
    offset: 0,
    hasMore: true
  }
}

export const resourceModule: Module<ResourceModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getSearchResources({ rootState, state, commit }, search): Promise<string> {
      try {
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const data: MeilisearchResult<Resource> = await ContentService.runSearch<Resource>({
          entity: 'resources',
          search: search ?? '',
          offset: 0,
          limit: state.pageSize,
          filter: formatFilter(roles)
        })
        commit('setOffset', 0)
        commit('setHasMore', data.nbHits > (data.hits.length))
        commit('setResources', data.hits)
        return ''
        } catch (error) {
          return (error instanceof Error) ? error.toString() : 'error on search resources.';
        }
  },
  async getMoreSearchResources({ rootState, state, commit }, search): Promise<string> {
    if (!state.hasMore) {
      return ''
    }
    try {
    commit('setOffset', state.offset + state.pageSize)
    const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
    const data: MeilisearchResult<Resource> = await ContentService.runSearch<Resource>({
      entity: 'contacts',
      search,
      offset: state.offset,
      limit: state.pageSize,
      filter: formatFilter(roles)
    })

    commit('setHasMore', data.nbHits > (state.resources.length + data.hits.length))
    commit('addResources', data.hits)
    return ''
    } catch (error) {
      return (error instanceof Error) ? error.toString() : 'error on search more resources.';
    }
  }
},
  mutations: {
    setResources(state, resources) {
      state.resources = resources
    },
    addResources(state, resources) {
      state.resources = [...state.resources, ...resources];
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setHasMore(state, more) {
      state.hasMore = more;
    },
  }
}
