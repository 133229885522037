
import { defineComponent } from "vue";
import { IonList, IonItem, IonLabel } from "@ionic/vue";
import { Article } from "@/models/article";

export default defineComponent({
  name: "OtherArticlesBox",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: {
    IonList,
    IonItem,
    IonLabel,
  },
  computed: {
    otherArticles(): Article[] {
      return this.$store.state.article.otherArticles;
    },
  },
});
