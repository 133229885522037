import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Landing from '../views/Landing.vue'
import Welcome from '../views/onboard/Welcome.vue'
import Login from '../views/onboard/Login.vue'
import Onboard from '../views/onboard/Onboard.vue'
import OnboardSuccess from '../views/onboard/OnboardSuccess.vue'
import Home from '../views/home/Home.vue'
import DashboardPage from '../views/home/Dashboard.vue'
import BioPage from '../views/home/Bio.vue'
import SettingsPage from '../views/home/Settings.vue'
import HelpPage from '../views/home/Help.vue'
import InfoPage from '../views/home/Info.vue'
import ArticlePage from '../views/home/Article.vue'
import ContactPage from '../views/home/Contact.vue'
import ContactsListPage from '../views/home/ContactsList.vue'
import SearchPage from '../views/home/Search.vue'
import EventsPage from '../views/home/Events.vue'
import EventPage from '../views/home/Event.vue'
import NotFound from '../views/404.vue'
import PrivacyPolicy from '../views/Privacy.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/landing'
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPolicy
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard
  },
  {
    path: '/success',
    name: 'OnboardSuccess',
    component: OnboardSuccess
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        redirect: '/home/dashboard'
      },
      {
        path: 'articles',
        redirect: '/home/dashboard',
      },
      {
        path: 'articles/:id',
        component:  ArticlePage
      },
      {
        path: 'dashboard',
        name: 'DashboardPage',
        component: DashboardPage
      },
      {
        path: 'events',
        name: 'EventsPage',
        component: EventsPage
      },
      {
        path: 'events/:id',
        component:  EventPage
      },
      {
        path: 'bio',
        name: 'BioPage',
        component: BioPage
      },
      {
        path: 'psych',
        name: 'PsychPage',
        component: BioPage
      },
      {
        path: 'social',
        name: 'SocialPage',
        component: BioPage
      },
      {
        path: 'spirit',
        name: 'SpiritPage',
        component: BioPage
      },
      {
        path: 'settings',
        name: 'SettingsPage',
        component: SettingsPage
      },
      {
        path: 'help',
        name: 'HelpPage',
        component: HelpPage
      },
      {
        path: 'info',
        name: 'InfoPage',
        component: InfoPage
      },
      {
        path: 'contacts',
        // component: ContactsPage
        component: ContactsListPage
      },
      {
        path: 'contacts/:id',
        component:  ContactPage
      },
      {
        path: 'search',
        component:  SearchPage
      },
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
