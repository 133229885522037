
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import CircleProgress from "vue3-circle-progress";

export default defineComponent({
  name: "CheckInSkip",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    CircleProgress,
  },
  props: {
    body: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    }
  }
});
