
import {
  IonButton,
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronForward } from "ionicons/icons";
import YrpLogo from "@/components/common/YrpLogo.vue";
import PrivacyDialogVue from "./PrivacyDialog.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    IonButton,
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonIcon,
    YrpLogo,
  },
  setup() {
    return { chevronForward };
  },
  computed: {
    getUser(): any {
      return this.$store.state.user.myUser;
    },
    version(): string {
      return process.env.VUE_APP_VERSION.slice(0, 3);
    }
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
    onboard() {
      this.$router.push("/onboard");
    },
    async openPrivacyPolicy() {
      const modal = await modalController.create({
        component: PrivacyDialogVue,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
        mode: "ios",
      });
      modal.present();
    },
  },
});
