// Format the filter queries for the search requests.

export function formatFilter(roles: string[]): string {

  // given roles provided, make a filter based on roles plus the none.

  let output = 'visibilityNames = none '
  roles.forEach((role) => {
    output += `OR visibilityNames = ${role} `
  })

  return output.trim()
}
