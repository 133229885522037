
import { defineComponent } from "vue";

export default defineComponent({
  name: "YrpLogo",
  props: {
    width: {
      type: String,
      default: '450'
    },
    height: {
      type: String,
      default: '184'
    }
  },
  calculated: {
    height() {
      const aspectrato = 5 / 3;
      return Number(this.width) / aspectrato
    }
  }
});
