
import { defineComponent } from "vue";
import { IonButton, IonIcon, IonRippleEffect } from "@ionic/vue";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "CloseButton",
  components: {
    IonButton,
    IonIcon,
    IonRippleEffect,
  },
  setup() {
    return { closeOutline };
  },
});
