
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { UserDetails } from "@/models/userDetails";
import { dateMixin } from "@/mixins/date.format";
import BackButton from "@/components/common/BackButton.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";

export default defineComponent({
  name: "MySettings",
  mixins: [dateMixin],
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    NotificationBell,
    BackButton,
    HelpButton
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    }
  },
  setup() {
    const loading = ref(false);
    const category = ref('');
    const src = ref('');
    const title = ref('');
    return { category, loading, src, title };
  },
  async ionViewWillEnter() {
    if (this.$route.query && this.$route.query.category) {
      this.category = this.$route.query.category.toString().toLowerCase();
      switch(this.category) {
        case 'biological':
          this.src = '/../assets/bio-circle.png';
          this.title = this.$t("menu.bio")
          break;
        case 'psychological':
          this.src = '/../assets/psych-circle.png';
          this.title = this.$t("menu.psych")
          break;
        case 'social':
          this.src = '/../assets/social-circle.png';
          this.title = this.$t("menu.social")
          break;
        case 'spiritual':
          this.src = '/../assets/spiritual-circle.png';
          this.title = this.$t("menu.spirit")
          break;
      }
    }
  },
  async mounted() {
    await this.$timer(1000)
    if (!this.userDetails) {
      this.$router.push("/home/dashboard");
    }
  },
  methods: {
  },
});
