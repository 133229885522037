import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w100 ion-text-start headline-5 my-capitalize" }
const _hoisted_2 = { style: {"height":"300px","width":"300px","min-width":"300px","max-width":"300px","overflow":"hidden","margin":"auto"} }
const _hoisted_3 = { style: {"height":"300px","width":"300px","min-width":"300px","max-width":"300px","overflow":"hidden","display":"flex","margin":"auto"} }
const _hoisted_4 = {
  key: 1,
  class: "auto body-1 ion-text-wrap flex-column"
}
const _hoisted_5 = { class: "mt-1 ion-text-center auto" }
const _hoisted_6 = { style: {"position":"absolute","bottom":"24px","display":"flex","width":"100%","z-index":"3"} }
const _hoisted_7 = { class: "w100 ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_wellness_circle = _resolveComponent("wellness-circle")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_yrp_circle_logo = _resolveComponent("yrp-circle-logo")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
      default: _withCtx(() => [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.myCheckIns.length
          ? _ctx.relativeDate(_ctx.myCheckIns[_ctx.activeSlide].created.seconds)
          : "") + "  ", 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { style: {"height":"348px"} }, {
      default: _withCtx(() => [
        (!_ctx.loading && _ctx.myCheckIns.length)
          ? (_openBlock(), _createBlock(_component_swiper, {
              key: 0,
              style: {"padding-top":"24px"},
              onActiveIndexChange: _ctx.moop,
              onSwiper: _ctx.onSwiper
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myCheckIns, (check, index) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: 'cat-' + index
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_wellness_circle, {
                          scale: "0.9",
                          size: 300,
                          one: check.spiritual,
                          two: check.social,
                          three: check.psychological,
                          four: check.biological
                        }, null, 8, ["one", "two", "three", "four"])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["onActiveIndexChange", "onSwiper"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_swiper, {
          style: {"padding-top":"24px"},
          ref: "slider4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_swiper_slide, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        style: {"transform":"scale(3)","margin":"24px auto","padding-top":"96px"},
                        class: "auto",
                        color: "primary",
                        name: "crescent"
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.myCheckIns.length && !_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_yrp_circle_logo, { style: {"height":"200px","withd":"200px","margin":"auto"} }),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("welcome.noCheck")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, !_ctx.myCheckIns.length || _ctx.loading]
        ])
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, [
          _createVNode(_component_ion_button, {
            fill: "empty",
            disabled: _ctx.activeSlide == 0,
            onClick: _ctx.lastSlide,
            icon: "",
            class: "my-pointer",
            style: _normalizeStyle({
            opacity: _ctx.activeSlide != 0 ? '1' : '0',
            'margin-right': '72px',
          })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                size: "large",
                icon: _ctx.chevronBack
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["disabled", "onClick", "style"])
        ]),
        _createElementVNode("span", null, [
          (_ctx.myCheckIns.length > 1)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                fill: "empty",
                disabled: _ctx.activeSlide == _ctx.myCheckIns.length - 1,
                onClick: _ctx.nextSlide,
                icon: "",
                class: "my-pointer",
                style: _normalizeStyle({
            opacity: _ctx.activeSlide != _ctx.myCheckIns.length - 1 ? '1' : '0',
            'margin-left': '72px',
          })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    size: "large",
                    icon: _ctx.chevronForward
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled", "onClick", "style"]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 512), [
      [_vShow, !_ctx.loading]
    ])
  ], 64))
}