
import { defineComponent } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import FavModal from "./FavModal.vue";
import FavouriteIcon from "./FavouriteIcon.vue";

export default defineComponent({
  name: "FavModalButton",
  props: {
    parent: {
      default: undefined,
    },
    articleId: {
      default: undefined,
      type: Number
    }
  },
  components: {
    IonButton,
    FavouriteIcon
  },
  computed: {
    favArticles(): any[] {
      return this.$store.state.article.favArticles;
    },
  },
  methods: {
    async openFavModal() {
      const modal = await modalController.create({
        mode: 'ios',
        component: FavModal,
        presentingElement: this.parent,
        backdropDismiss: true,
        swipeToClose: true,
      });
      modal.present();
    },
  }
});
