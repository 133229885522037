
import { defineComponent } from "vue";
import { IonContent, modalController } from "@ionic/vue";
import CloseButton from "./../../components/common/CloseButton.vue";
import Policy from "@/components/common/Policy.vue";

export default defineComponent({
  name: "PrivacyDialog",
  components: {
    IonContent,
    CloseButton,
    Policy
  },
  methods: {
    closeMe() {
      modalController.dismiss();
    },
  },
});
