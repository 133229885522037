import { UserDetails } from '@/models/userDetails';
import { Module } from 'vuex';
import { localStorage } from './../main';
import { LocalNotifications, ScheduleOptions } from "@capacitor/local-notifications";
import { DateTime } from 'luxon';
import { firebaseService } from '@/main';

interface UserModuleState {
  myUser: any; // firebase user object
  myDetails: UserDetails | null; // saved user details
  myRegionId: any; // loaded from localstorage, instead of saving to myDetails
  scrollTop: boolean; // watched to trigger scroll on nav.
  myPhrase: any; // used only on onboard
  innerWidth: any; // lazy place to re use this variable. shh.
  initComplete: boolean;
}

const getDefaultState = () => {
  return {
    myUser: null as any,
    myDetails: null,
    myRegionId: null,
    scrollTop: false,
    myPhrase: '',
    innerWidth: 0,
    initComplete: false
  }
}

export const userModule: Module<UserModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async listenForUser({ commit, dispatch }, isMobile?: boolean) {
      if (process.env.NODE_ENV == 'development') {
        // use a mock user for dev
        commit('setMyUser', { uid: '123', activated: { seconds: 12321332 } })
        commit('setDetailsDirectly', new UserDetails(
          { seconds: 12321332 },
          false,
          'weekly',
          true,
          4,
          true,
          87,
          77,
          94,
          88,
          78,
          33,
          21,
          44,
          19,
          44,
          ['Family'],
          'mocks214sf',
        )) // even for dev.
        dispatch('loadAllUserData', isMobile)
        return
      }
      // listen for user changes.
      firebaseService.onAuthStateChange(async (firebaseUser: any) => {
        commit('setMyUser', firebaseUser)
        dispatch('loadAllUserData', isMobile)
        // let stats know
        firebaseService.updateStats({entity: 'sessions'})
        firebaseService.getAnalytics().logEvent('session_started');
        commit('setInitComplete', true)
      }, (error: any) => {
        commit('setInitComplete', true)
      });
    },
    async loadAllUserData({ state, dispatch },  isMobile?: boolean) {
        if (state.myUser) {
          // load all you need here
          dispatch('loadFromStorage')
          await dispatch('getUserDetails')
          await dispatch('check/loadCheckIns', null, {root:true})
          await dispatch('check/loadQuestionResponses', null, {root:true})
          dispatch('contact/getRegions', null, {root:true})
          dispatch('contact/getProviders', null, {root:true})
          // dispatch('contact/getSearchContacts', null, {root:true})
          dispatch("event/getEvents", new Date(), {root:true})
          dispatch('question/getCheckInQuestions', null, {root:true})
          dispatch('article/getFavArticles', null, {root:true})
          if (isMobile && state.myDetails && state.myDetails.notificationsEnabled) {
            dispatch("checkAndSetLocalNotifications")
          }
        }
    },
    async login({ state }, phrase: string): Promise<string> {
      try {
        await firebaseService.login(phrase)
        // user creds are automatically set by authChangeDetect.
        return ''
      } catch (error) {
        // error msg string to show user
        console.log(error)
        return (error instanceof Error) ? error.toString() : 'error logging in.';
      }
    },
    async logout({ commit }): Promise<string> {
      try {
        await firebaseService.logout()
        commit('resetUser')
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on logout.';
      }
    },
    async activate({ state, commit }, activationCode: string): Promise<string> {
      try {
        // if already an activated user dont do this!
        if (state.myUser && !state.myUser.isAnonymous) {
          return 'user already activated'
        } else if (state.myUser && state.myUser.isAnonymous) {
          console.log('already have a user lets not create one.')
        } else {
          // check code first, then create user (less unneccessary users this way)
          try {
            await firebaseService.activateUserAndLogin({ activationCode: activationCode ?? '', check: true })
          } catch (error) {
            return (error instanceof Error) ? error.toString() : 'error on activation.'; // this will happen if code is no good.
          }
          // creates an anonymous user, gets us an id.
          await firebaseService.createUser()
        }
        // at this point we already have a user, or we awaited a new one.
        const phrase = await firebaseService.activateUserAndLogin({ activationCode: activationCode ?? '', check: false })
        commit('setPhrase', phrase)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on activation.';
      }
    },
    async updateUserDetails({ state, dispatch }, userDetails: UserDetails): Promise<string> {
      try {
        if (!state.myUser || !state.myUser.uid) {
          return 'no user to update details of.';
        }
        await firebaseService.updateUserDetails(state.myUser.uid, userDetails);
        dispatch('getUserDetails');
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on user update.';
      }
    },
    async getUserDetails({ state, commit }): Promise<string> {
      try {
        if (!state.myUser || !state.myUser.uid) return 'no user to load details for.';
        const snapshot = await firebaseService.getUserDetails(state.myUser.uid);
        commit('setDetails', snapshot)
        return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on user fetch.';
      }
    },
    async clearPhrase({ commit }) {
      commit('setPhrase', '')
    },
    async loadFromStorage({ commit }) {
      // with this setup we need to know what keys to pull..
      const myRegionId = await localStorage.get('myRegionId')
      if (myRegionId !== null && myRegionId != undefined ) {
        commit('setData', {myRegionId: Number(myRegionId)})
      }
    },
    async saveToStorage({ commit }, {key, value}) {
      // will overwrite any value.
      await localStorage.set(key, value)
      commit('setData', {[key]: value})
    },
    async triggerScrollTop({ commit }) {
      commit('setScrollTop', true)
      setTimeout(()=> {
        commit('setScrollTop', false)
      }, 500)
    },
    saveInnerWidth({ commit }, width) {
      commit('setInnerWidth', width);
    },

    async clearLocalNotifications() {
      try {
        const pendingList = await LocalNotifications.getPending();
        if (pendingList && pendingList.notifications && pendingList.notifications.length) {
          await LocalNotifications.cancel(pendingList)
        }
      } catch (err) {
        console.log(err)
      }

    },

    async checkAndSetLocalNotifications({ state, dispatch }) {
      if (state.myDetails && state.myDetails.notificationsEnabled) {
        // okay great you have them enabled.
        // lets grab the pending ones,
        try {
        const result = await LocalNotifications.checkPermissions();
        if (result && result.display == 'granted') {
          // okay great lets find out if they have any
          const pendingList = await LocalNotifications.getPending();

          if (pendingList.notifications && pendingList.notifications.length) {
            // dont bother if you already have one, we only keep one max.
            return
          } else {
            // we don't have any notifications upcoming, lets add one.
            const options: ScheduleOptions = {notifications: [{
              title: 'WellnessFirst Check-In Reminder',
              body: `complete your ${state.myDetails.interval} check-in`,
              id: 1 // always using one so that there is only one.
            }]};
            // TODO, consider an time of day setting to save. instead of 11.
            let duration = {}
            switch(state.myDetails.interval) {
              case 'daily': duration = {days: 1}; break;
              case 'weekly': duration = {weeks: 1}; break;
              case 'monthly': duration = {months: 1}; break;
            }
            options.notifications[0].schedule = { at:DateTime.now().plus(duration).set({hour: 11}).toJSDate() }
            await LocalNotifications.schedule(options);
          }
        } else {
        // if no permission, quietly fail and set enabled to false.
          dispatch("updateUserDetails", {
            notificationsEnabled: false,
          });
        }
      } catch (err) {
          dispatch("updateUserDetails", {
            notificationsEnabled: false,
          });
      }
      } else {
        console.log('tried to check for local notifs, but settings says no.')
      }
    }
  },
  mutations: {
    resetUser(state) {
      Object.assign(state, getDefaultState())
    },
    setMyUser(state, firebaseUser) {
      state.myUser = firebaseUser
    },
    setPhrase(state, phrase: string) {
      state.myPhrase = phrase
    },
    setDetails(state, snapshot) {
      snapshot.forEach((doc: any) => {
        const c = doc.data()
        c.id = doc.id
        // TODO temp if no role, give them one.
        if (!c.roles) {
          c.roles = ['Family']
        }
        state.myDetails = c
      });
    },
    // the following are only used in dev.
    setDetailsDirectly(state, details) {
      state.myDetails = details
    },
    setData(state, storageData) {
      state = Object.assign(state, storageData);
    },
    setScrollTop(state, trigger) {
      state.scrollTop = trigger
    },
    setInnerWidth(state, value) {
     state.innerWidth = value; 
    },
    setInitComplete(state, val) {
      state.initComplete = val
    }
  }
}
