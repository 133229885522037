import qs from 'qs'
import { DateTime } from 'luxon';

// format query for cms requests
export function formatArticleQuery(queryObject: object, visibilities: any[], limit: number, start: number): string {
  const formattedVis: any[] = visibilities.map(v => {return { visibilityNames_contains: v }})
  formattedVis.push({ visibilityNames_null: true })
  formattedVis.push({ visibilityNames_contains: 'none' })

  const sortOrder = 'priorityLevel:asc,published_at:desc'

  return '?' + qs.stringify({
    _start: start || 0,
    _limit: limit || 5,
    _sort: sortOrder,
    _where: [
     queryObject,
     { _or: [{shell_null: true}, {shell: false} ]},
     { _or: formattedVis }
    ]
  })
}

export function formatEventQuery(visibilities: any[], limit: number, start: number): string {
  const formattedVis: any[] = visibilities.map(v => {return { visibilityNames_contains: v }})
  formattedVis.push({ visibilityNames_null: true })
  formattedVis.push({ visibilityNames_contains: 'none' })
  
  const sortOrder = 'endDate:desc'

  return '?' + qs.stringify({
    _start: start || 0,
    _limit: limit || 5,
    _sort: sortOrder,
    _where: [
     { endDate_gte: DateTime.now().toISO() },
     { _or: formattedVis }
    ]
  })
}

export function formatContactQuery(queryObject: object, visibilities: any[], limit?: number, start?: number) {
  const formattedVis: any[] = visibilities.map(v => {return { visibilityNames_contains: v }})
  formattedVis.push({ visibilityNames_null: true })
  formattedVis.push({ visibilityNames_contains: 'none' })

  const sortOrder = 'created_at:desc'

  return '?' + qs.stringify({
    _start: start || 0,
    _limit: limit || 5,
    _sort: sortOrder,
    _where: [
      queryObject,
     { _or: formattedVis }
    ]
  })
}