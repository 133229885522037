
import { defineComponent } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { dateMixin } from "@/mixins/date.format";
import BackButton from "@/components/common/BackButton.vue";
import Policy from "@/components/common/Policy.vue";



export default defineComponent({
  name: "PrivacyPolicy",
  mixins: [dateMixin],
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    BackButton,
    Policy
  },
});
