import { Module } from 'vuex'
import { Contact } from '@/models/contact';
import { ContentService } from '@/services/strapi.service';
import { Region } from '@/models/region';
import { Provider } from '@/models/provider';
import { MeilisearchResult } from './article';
import { firebaseService } from '@/main';
import { formatContactQuery } from '@/services/qs.service';
import { formatFilter } from '@/services/meil.service';

interface ContactModuleState {
  recomContacts: Contact[];
  contacts: Contact[];
  contact: Contact;
  regions: Region[];
  providers: Provider[];
  pageSize: number;
  offset: number;
  hasMore: boolean;
}

const getDefaultState = () => {
  return {
    recomContacts: [],
    contacts: [],
    contact: null as any,
    regions: [],
    providers: [],
    pageSize: 10,
    offset: 0,
    hasMore: true
  }
}

export const contactModule: Module<ContactModuleState, any> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    // Get contacts from main api
    async getContacts({ rootState, state, commit }, query): Promise<string> {
      try {
        commit('setOffset', 0)
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const contacts = await ContentService.getMany<Contact>('contacts', formatContactQuery(query, roles, state.pageSize + 1, 0))
        if (contacts) {
          commit('setHasMore', contacts.length > state.pageSize)
          commit('setContacts', contacts.slice(0, state.pageSize))
          return ''
        } else {
          commit('setContacts', [])
          return 'could not load contacts'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on contacts load.';
      }
    },
    // Get mire contacts from main api
    async getMoreContacts({ rootState, commit, state }, query): Promise<string> {
      try {
        if (!state.hasMore) {
          return ''
        }
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const contacts = await ContentService.getMany<Contact>('contacts', formatContactQuery(query, roles, state.pageSize + 1, state.offset + state.pageSize))
        commit('setOffset', state.offset + state.pageSize)
        if (contacts) {
          commit('setHasMore', contacts.length > state.pageSize)
          commit('addContacts', contacts.slice(0, state.pageSize))
          return ''
        } else {
          return 'could not add contacts'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get contacts.';
      }
    },
    async getContact({ commit }, contactId): Promise<string> {
      try {
        const contact = await ContentService.getOne<Contact>('contacts', contactId)
        if (contact) {
          commit('setContact', contact)
          firebaseService.updateStats({entity: 'contacts', id: contactId})
          firebaseService.getAnalytics().logEvent('contact_read');
          return ''
        } else {
          commit('setContact', null)
          return 'could not load contact'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on get contact.';
      }
    },
    async getRegions({ commit }): Promise<string> {
      try {
        const regions = await ContentService.getMany<Region>('regions')
        if (regions) {
          commit('setRegions', regions)
          return ''
        } else {
          commit('setRegions', [])
          return 'could not load regions'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on regions load.';
      }
    },
    async getProviders({ commit }): Promise<string> {
      try {
        const providers = await ContentService.getMany<Provider>('providers')
        if (providers) {
          commit('setProviders', providers)
          return ''
        } else {
          commit('setProviders', [])
          return 'could not load providers'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on providers load.';
      }
    },
    async getRecommendedContacts({ rootState, commit }, query): Promise<string> {
      try {
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const contacts = await ContentService.getMany<Contact>('contacts', formatContactQuery(query, roles, 3, 0))

        if (contacts) {
          commit('setRecomContacts', contacts)
          return ''
        } else {
          commit('setRecomContacts', [])
          return 'could not load recommended contacts'
        }
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on recommended contacts load.';
      }
    },
    async getSearchContacts({ rootState, state, commit }, search): Promise<string> {
      try {
        const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
        const data: MeilisearchResult<Contact> = await ContentService.runSearch<Contact>({
          entity: 'contacts',
          search: search ?? '',
          offset: 0,
          limit: state.pageSize,
          filter: formatFilter(roles)
        })
        commit('setOffset', 0)
        commit('setHasMore', data.nbHits > (data.hits.length))
        commit('setContacts', data.hits)
        return ''
        } catch (error) {
          return (error instanceof Error) ? error.toString() : 'error on search resources.';
        }
    },
    async getMoreSearchContacts({ rootState, state, commit }, search): Promise<string> {
      if (!state.hasMore) {
        return ''
      }
      try {
      commit('setOffset', state.offset + state.pageSize)
      const roles = rootState.user.myDetails ? rootState.user.myDetails.roles : [];
      const data: MeilisearchResult<Contact> = await ContentService.runSearch<Contact>({
        entity: 'contacts',
        search,
        offset: state.offset,
        limit: state.pageSize,
        filter: formatFilter(roles)
      })

      commit('setHasMore', data.nbHits > (state.contacts.length + data.hits.length))
      commit('addContacts', data.hits)
      return ''
      } catch (error) {
        return (error instanceof Error) ? error.toString() : 'error on search more contacts.';
      }
    }
  },
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts
    },
    addContacts(state, contacts) {
      state.contacts = [...state.contacts, ...contacts];
    },
    setRecomContacts(state, contacts) {
      state.recomContacts = contacts
    },
    setContact(state, contact) {
      state.contact = contact
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setProviders(state, providers) {
      state.providers = providers
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setHasMore(state, more) {
      state.hasMore = more;
    },
  }
}
