import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","height":"100%"} }
const _hoisted_2 = { class: "headline-5 ion-text-center px-2" }
const _hoisted_3 = { class: "headline-5" }
const _hoisted_4 = { class: "body-2 o-87" }
const _hoisted_5 = { class: "headline-5" }
const _hoisted_6 = { class: "body-2 o-87" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yrp_logo = _resolveComponent("yrp-logo")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "4",
                      "size-md": "6",
                      size: "10"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_yrp_logo)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4 pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "4",
                      "size-md": "8",
                      size: "10",
                      class: _normalizeClass(_ctx.loading ? 'o-5' : '')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t("login.message")), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_login_form, {
                  submitHandler: _ctx.onSubmit,
                  loading: _ctx.loading,
                  errorTxt: _ctx.errorTxt
                }, null, 8, ["submitHandler", "loading", "errorTxt"]),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "6",
                      "size-md": "8",
                      size: "10",
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t("login.hint")), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("login.loginHint")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-center pa-4 mt-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-lg": "6",
                      "size-md": "8",
                      size: "10",
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t("login.signUpOption")), 1),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("a", {
                            class: "my-underline my-pointer",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/onboard')))
                          }, _toDisplayString(_ctx.$t("login.signUpLink")), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}