import axios from 'axios';
import { firebaseService } from '@/main' ;

export function setUpInterceptor () {
  
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL; // process.env.VUE_APP_API_BASE_URL; // process.env.VUE_APP_DEV_API_URL; // 

  axios.interceptors.request.use(async (config: any) => {
    if (axios.defaults.headers.common.Authorization == null) {
      try {
        const token = await firebaseService.getToken();
        if (!token) {
          return config
        }
        const newConfig = Object.assign({}, config, { headers: {} })
        newConfig.headers.Authorization = `Bearer ${token}`
        return newConfig
      } catch (err) {
        console.log(err)
        return config
      }
    }
    return config
  });
}

export async function getVersion(): Promise<number> {
  try {
      const { data } = await axios.get(process.env.VUE_APP_VERSION_API)
      console.log(data)
      return Number(data.version)
    } catch (err) {
      return 0
    }
}
