
import { defineComponent, ref } from "vue";
import { IonRadio, IonRadioGroup, IonRow, IonCol } from "@ionic/vue";
export default defineComponent({
  name: "QuestionRow",
  components: {
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
  },
  setup() {
    const value = ref(0);
    return { value };
  },
  props: {
    inputVal: {
      type: Number,
      default: 0,
    },
    question: {
      type: String,
      default: "  ",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.inputVal) {
      this.value = this.inputVal;
    }
  },
});
