
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  isPlatform
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { newspaperOutline, folderOutline, peopleOutline } from "ionicons/icons";
import SkeletonFeed from "@/components/common/SkeletonFeed.vue";
import ArticlesFeed from "@/components/home/dashboard/ArticlesFeed.vue";
import ContactFeed from "@/components/home/dashboard/ContactFeed.vue";
import ResourceFeed from "@/components/home/dashboard/ResourcesFeed.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import { Keyboard } from '@capacitor/keyboard';
import SidebarBox from "@/components/common/SidebarBox.vue";
import UpcomingEventsBox from "@/components/common/UpcomingEventsBox.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";

export default defineComponent({
  name: "SearchPage",
  components: {
    ArticlesFeed,
    ContactFeed,
    ResourceFeed,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    SkeletonFeed,
    BackButton,
    NotificationBell,
    HelpButton,
    SidebarBox,
    OtherArticlesBox,
    ResourcesInArea,
    UpcomingEventsBox
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    const search = ref("");
    const tab = ref('articles')
    const isBrowser = isPlatform("desktop");
    return { content, loading, search, tab, isBrowser, newspaperOutline, folderOutline, peopleOutline };
  },
  methods: {
    async load() {
      this.loading = true;
      switch (this.tab) {
        case 'articles': await this.$store.dispatch("article/getSearchArticles", this.search); break;
        case 'contacts': await this.$store.dispatch("contact/getSearchContacts", this.search); break;
        case 'resources': await this.$store.dispatch("resource/getSearchResources", this.search); break;
        default: break;
      }
      this.loading = false;
    },
    async loadMore(event: any) {
      switch (this.tab) {
        case 'articles': await this.$store.dispatch("article/getMoreSearchArticles", this.search); break;
        case 'contacts': await this.$store.dispatch("contact/getMoreSearchContacts", this.search); break;
        case 'resources': await this.$store.dispatch("resource/getMoreSearchResources", this.search); break;
        default: break;
      }
      if (event) {
        event.target!.complete();
      }
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    },
    closeMe() {
      // close keyboard, force it
      if (!this.isBrowser) {
        Keyboard.hide();
      }
    },
    segmentChanged(event: any) {
      this.load()
    }
  },
  async ionViewWillEnter() {
    await this.$timer(1000)
    if (this.$route.query && this.$route.query.search) {
      this.search = this.$route.query.search.toString();
    }
    if (this.$route.query && this.$route.query.tab) {
      this.tab = this.$route.query.tab.toString();
    }
    this.load()
  },
});
