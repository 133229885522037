import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "headline-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_button = _resolveComponent("back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_notification_bell = _resolveComponent("notification-bell")!
  const _component_help_button = _resolveComponent("help-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_skeleton_feed = _resolveComponent("skeleton-feed")!
  const _component_event_feed = _resolveComponent("event-feed")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_resources_in_area = _resolveComponent("resources-in-area")!
  const _component_other_articles_box = _resolveComponent("other-articles-box")!
  const _component_sidebar_box = _resolveComponent("sidebar-box")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { onClick: _ctx.scrollTop }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, {
                slot: "start",
                class: "ion-hide-lg-up"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_notification_bell, {
                    parent: _ctx.$parent!.$refs.ionRouterOutlet
                  }, null, 8, ["parent"]),
                  _createVNode(_component_help_button)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { ref: "content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "w100" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("events.title")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("events.desc")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, { class: "pa-4" }),
                          _createVNode(_component_ion_row, { class: "ion-justify-content-center px-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, {
                                "size-lg": "12",
                                "size-md": "12",
                                size: "12"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.loading)
                                    ? (_openBlock(), _createBlock(_component_skeleton_feed, { key: 0 }))
                                    : _createCommentVNode("", true),
                                  (!_ctx.loading)
                                    ? (_openBlock(), _createBlock(_component_event_feed, { key: 1 }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.$store.state.user.innerWidth > 1400)
                    ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_sidebar_box, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_resources_in_area),
                              _createVNode(_component_other_articles_box, { title: "Related Articles" })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadMore($event))),
            threshold: "100px",
            id: "infinite-scroll"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": "crescent" })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}