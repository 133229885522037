
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToggle,
  modalController,
  isPlatform,
} from "@ionic/vue";
import { UserDetails } from "@/models/userDetails";
import { dateMixin } from "@/mixins/date.format";
import CircleProgress from "vue3-circle-progress";
import { useSnackbarPlugin } from "snackbar-vue";
import WalnutModal from "@/components/home/wizard/WalnutModal.vue";
import BackButton from "@/components/common/BackButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import { colorMixin } from "@/mixins/get.color";
import RegionSelector from "@/components/home/wizard/RegionSelector.vue";
import { LocalNotifications } from "@capacitor/local-notifications";
import PrivacyDialogVue from "../onboard/PrivacyDialog.vue";

export default defineComponent({
  name: "MySettings",
  mixins: [dateMixin, colorMixin],
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToggle,
    CircleProgress,
    NotificationBell,
    BackButton,
    HelpButton,
    RegionSelector
  },
  computed: {
    userDetails(): UserDetails {
      return this.$store.state.user.myDetails;
    },
    user(): any {
      return this.$store.state.user.myUser;
    },
    lowestTopic(): { topic: string; value: number } {
      return this.$store.getters["check/lowestTopic"];
    },
    lowestCategory(): { category: string; value: number } {
      return this.$store.getters["check/lowestCategory"];
    },
    version(): string {
      return process.env.VUE_APP_VERSION;
    }
  },
  setup() {
    const loading = ref(true);
    const notificationsEnabled = ref(false);
    const interval = ref("weekly");
    const snack = useSnackbarPlugin();
    const notificationsPossible = isPlatform("android") || isPlatform("ios");
    const notificationsPermission = ref('');
    return { loading, interval, notificationsEnabled, snack, notificationsPossible, notificationsPermission };
  },
  async mounted() {
    await this.$timer(1000)
    if (this.userDetails) {
      this.setForm()
    } else {
      this.$router.push("/home/dashboard");
    }
    this.loading = false;
  },
  methods: {
    async openWizardDialog() {
      const modal = await modalController.create({
        component: WalnutModal,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
        mode: 'ios'
      });
      await modal.present();
      modal.onDidDismiss().then((data)=> {
        if (data) {
          this.setForm()
        }
      })
    },
    async saveChanges() {
      if ((this.userDetails && (this.interval == this.userDetails.interval)) && (this.userDetails && (this.notificationsEnabled == this.userDetails.notificationsEnabled))) {
        return
      }
      this.loading = true;
      const errorMsg = await this.$store.dispatch("user/updateUserDetails", {
        interval: this.interval,
        notificationsEnabled: this.notificationsEnabled,
      });
      this.snack.show({
          position: "bottom",
          text:
            errorMsg && errorMsg.length
              ? errorMsg
              : "Settings Updated Successfully",
          time: 3000,
          close: true,
        });
      await this.$timer(1000)
      this.setForm() // this setform is redundant.
      this.loading = false;
    },
    setForm() {
      this.interval = this.userDetails.interval ?? "weekly";
      this.notificationsEnabled =
        this.userDetails.notificationsEnabled ?? false;
    },
    async toggleNotifications() {
      if (this.loading || !this.notificationsPossible) return
      // if this is set, we already have this on.
      // so all we are doing is saving set to false.
      if (this.userDetails.notificationsEnabled) {
        await this.saveChanges()
        this.$store.dispatch("user/clearLocalNotifications") // clear old ones.
        // we are not yet enabled, so lets check
      } else if (!this.loading) {
        const result = await LocalNotifications.requestPermissions();
        if (result.display == 'granted') {
          await this.saveChanges();
          await this.$store.dispatch("user/clearLocalNotifications")
          this.$store.dispatch("user/checkAndSetLocalNotifications")
        } else {
          this.notificationsEnabled = false; // force this to false.
          this.snack.show({
            position: "bottom",
            text: 'notification permissions were denied.',
            time: 3000,
            close: true,
          });
        }
      }
    },
    async toggleInterval() {
      if (this.loading || !this.notificationsPossible) return
      if (this.userDetails.interval == this.interval) {
        this.saveChanges()
      } else if (!this.loading) {
        // else means we changed the interval, have to reset our next notification.
        await this.saveChanges();
        await this.$store.dispatch("user/clearLocalNotifications")
        this.$store.dispatch("user/checkAndSetLocalNotifications")
      }
    },
    async openPrivacyPolicy() {
      const modal = await modalController.create({
        component: PrivacyDialogVue,
        presentingElement: this.$parent!.$refs.ionRouterOutlet as any,
        backdropDismiss: true,
        swipeToClose: true,
        mode: "ios",
      });
      modal.present();
    },
  },
});
