
import { defineComponent, ref } from "vue";
import {
  IonIcon,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonLabel,
} from "@ionic/vue";
import { thumbsDownOutline } from "ionicons/icons";
import { EventEntity } from "@/models/event";
import { dateMixin } from "@/mixins/date.format";
import { colorMixin } from "@/mixins/get.color";
export default defineComponent({
  name: "EventFeed",
  mixins: [dateMixin, colorMixin],
  components: {
    IonCard,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonRippleEffect,
    IonLabel,
  },
  computed: {
    events(): EventEntity[] {
      return this.$store.state.event.events;
    },
  },
  async mounted() {
    this.$timer(2000);
    this.loading = false;
  },
  setup() {
    const loading = ref(true);
    return { loading, thumbsDownOutline };
  },
  methods: {
    getDate(dateISOString: string) {
      return this.dateFormat("dd", dateISOString);
    },
    getDay(dateISOString: string) {
      return this.dateFormat("EEEE", dateISOString);
    },
    getMonth(dateISOString: string) {
      return this.dateFormat("MMMM", dateISOString);
    },
    formatNicely(dateISOString: string) {
      return this.dateFormat("h:mm a", dateISOString);
    },
  },
});
