
import { alertController, IonApp, IonRouterOutlet, IonSplitPane, isPlatform } from "@ionic/vue";
import { defineComponent } from "vue";
import MainMenu from "@/components/home/MainMenu.vue";
import { Network } from '@capacitor/network';
import { getVersion } from "./services/axios.service";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    MainMenu,
  },
  computed: {
    getUser(): any {
      return this.$store.state.user.myUser;
    },
  },
  setup() {
    const isMobile = isPlatform("android") || isPlatform("ios");
    return { isMobile };
  },
  async mounted() {
    // check connection.
    const status = await Network.getStatus();
    if (!status || !status.connected) {
      const alert = await alertController.create({
          header: 'No Network Detected',
          message: `Sorry, WellnessFirst requires an internet connection.`,
          buttons: ['OK']
        });
        await alert.present();
    }

   if (this.isMobile) {
    const serverVersionNumber = await getVersion();
    if (serverVersionNumber && serverVersionNumber > process.env.VUE_APP_VERSION_NUMBER) {
      const alert = await alertController.create({
          header: 'App update is available',
          message: `Would you like to download the update now?`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
            },
            {
              text: 'Yes',
              id: 'confirm-button',
              handler: () => {
                if (isPlatform("android")) {
                  window.open(process.env.VUE_APP_PLAY_STORE_LINK)
                } else if (isPlatform("ios")) {
                  window.open(process.env.VUE_APP_APP_STORE_LINK)
                }
              },
            },
          ]
        });
        await alert.present();
    }
   }



    // listen for firebase user.
    this.$store.dispatch("user/listenForUser", this.isMobile, { root: true });
    // determine if logged in after user is populated.
    if (this.isAuthdPage(this.$route.path)) {
      if (!this.getUser) {
        // if we don't have user and authed page, lets wait a second.
        await this.$timer(3000);
      } // still no? leave.
      if (!this.getUser) {
        this.$router.push("/login");
      }
    }
  },
  beforeCreate() {
    this.$router.beforeEach(async (to, from, next) => {
      const authdPath = this.isAuthdPage(to.path);
      if (authdPath && !this.getUser) {
        // if we don't have user and authed page, lets wait a second.
        await this.$timer(1000);
      }
      if (authdPath && !this.getUser) {
        next({ path: "/login" });
      } else {
        next();
      }
    });
  },
  methods: {
    isAuthdPage(path: string): boolean {
      if (process.env.NODE_ENV == "development") {
        return false; // dont bother checking in dev.
      }
      // all auth'd routes are under /home
      return path.includes('home');
    },
    // Used later if we add additional languages.
    checkLocale() {
      try {
        const lang = navigator.language.toString().toUpperCase();
        if (lang.includes("ES")) {
          this.$i18n.locale = "en"; // "es"
        } else {
          this.$i18n.locale = "en";
        }
      } catch (error) {
        console.log("could not check locale", error);
      }
    }
  }
});
