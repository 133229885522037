
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonList,
  IonLabel,
  IonItem,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/vue";
import { Contact } from "@/models/contact";
import { Region } from "@/models/region";
import RegionSelector from "@/components/home/wizard/RegionSelector.vue";
import ContactListItem from "@/components/home/contacts/ContactListItem.vue";

export default defineComponent({
  components: {
    IonButton,
    IonList,
    IonLabel,
    IonItem,
    IonSkeletonText,
    IonThumbnail,
    RegionSelector,
    ContactListItem,
  },
  props: {
    category: {
      type: String,
      default: null
    }
  },
  setup() {
    const loading = ref(true);
    const mocks = [1, 2, 3];
    return { loading, mocks };
  },
  computed: {
    contacts(): Contact[] {
      if (this.$store.state.contact.contacts.length) {
        return this.$store.state.contact.contacts.slice(0, 3);
      } else {
        return [];
      }
    },
    myRegionId(): any {
      return this.$store.state.user.myRegionId;
    },
    regions(): Region[] {
      return this.$store.state.contact.regions;
    },
    myRegion(): Region | undefined {
      if (this.regions && this.regions.length && this.myRegionId) {
        return this.regions.find((r) => r.id == this.myRegionId);
      }
      return undefined;
    },
    lowestCategoryName(): { category: string; value: number } {
      const c = this.$store.getters["check/lowestCategory"];
      return c ? c.category : ''
    },
  },
  methods: {
    async loadContacts() {
      if (this.myRegion) {
        const query: any = {'region.name' : this.myRegion.name}
        if (this.category) {
          query['category.name'] = this.category
        } else if (this.lowestCategoryName) {
          query['category.name'] = this.lowestCategoryName
        }
        await this.$store.dispatch("contact/getContacts", query);
      }
      this.loading = false;
    },
    getColor(category: string) {
      switch (category.toLowerCase()) {
        case "biological":
          return "153, 202, 60";
        case "psychological":
          return "88, 196, 235";
        case "social":
          return "244, 123, 75";
        case "spiritual":
          return "154, 87, 163";
        default:
          return "255, 255, 255";
      }
    },
  },
  async mounted() {
    await this.$timer(2000);
    this.loadContacts();
  },
});
