
import { defineComponent, ref } from "vue";
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { dateMixin } from "@/mixins/date.format";
import BackButton from "@/components/common/BackButton.vue";
import HelpButton from "@/components/common/HelpButton.vue";
import NotificationBell from "@/components/common/NotificationBell.vue";
import SkeletonFeed from "@/components/common/SkeletonFeed.vue";
import EventFeed from "@/components/home/dashboard/EventFeed.vue";
import SidebarBox from "@/components/common/SidebarBox.vue";
import ResourcesInArea from "@/components/home/dashboard/ResourcesInArea.vue";
import OtherArticlesBox from "@/components/common/OtherArticlesBox.vue";

export default defineComponent({
  name: "EventsPage",
  mixins: [dateMixin],
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    NotificationBell,
    BackButton,
    HelpButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    SkeletonFeed,
    EventFeed,
    OtherArticlesBox,
    SidebarBox,
    ResourcesInArea
  },
  setup() {
    const loading = ref(true);
    const content = ref();
    return { content, loading  };
  },
  async ionViewWillEnter() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true;
      await this.$store.dispatch("event/getEvents");
      this.loading = false;
    },
    async loadMore(event: any) {
      this.loading = true;
      await this.$store.dispatch("event/getMoreEvents");
      if (event) {
        event.target!.complete();
      }
      this.loading = false;
    },
    scrollTop() {
      this.content.$el.scrollToTop(300);
    }
  },
});
