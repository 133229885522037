import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_CustomControl = _resolveComponent("CustomControl")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!

  return (_openBlock(), _createBlock(_component_GoogleMap, {
    "api-key": _ctx.apiKey,
    style: _normalizeStyle([{"width":"100%"}, {'height':  _ctx.height}]),
    center: _ctx.center,
    zoom: _ctx.zoom,
    disableDefaultUi: true,
    ref: "mapRef"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (mark, index) => {
        return (_openBlock(), _createBlock(_component_Marker, {
          onClick: ($event: any) => (_ctx.$emit('goto', mark)),
          options: _ctx.mapRef ? _ctx.formatMark(mark) : {},
          key: 'mark-' + mark.lat + '-' + index
        }, null, 8, ["onClick", "options"]))
      }), 128)),
      _createVNode(_component_CustomControl, { position: "TOP_LEFT" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      (_ctx.find)
        ? (_openBlock(), _createBlock(_component_CustomControl, {
            key: 0,
            position: "RIGHT_BOTTOM",
            class: "pr-2 pb-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('findme'))),
                translucent: "",
                mode: "ios",
                color: "light",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    size: "large",
                    button: "",
                    icon: _ctx.locate
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["api-key", "style", "center", "zoom"]))
}