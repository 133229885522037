import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "headline-2" }
const _hoisted_3 = { class: "headline-6" }
const _hoisted_4 = { class: "headline-6" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "headline-6" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = { class: "py-2" }
const _hoisted_10 = { class: "headline-6" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "py-2" }
const _hoisted_13 = { class: "headline-6" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "py-2" }
const _hoisted_16 = { class: "py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_button = _resolveComponent("back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_notification_bell = _resolveComponent("notification-bell")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, {
                slot: "start",
                class: "ion-hide-lg-up"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_notification_bell, {
                    parent: _ctx.$parent!.$refs.ionRouterOutlet
                  }, null, 8, ["parent"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { size: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("help.title")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t("help.subTitle")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("help.welcome")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t("menu.bio")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "auto",
                      alt: "bio-circle-category",
                      style: {"height":"192px","width":"192px"},
                      src: '/../assets/bio-circle.png'
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("help.bio")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.$t("menu.psych")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "auto",
                      alt: "psych-circle-category",
                      style: {"height":"192px","width":"192px"},
                      src: '/../assets/psych-circle.png'
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("help.psych1")), 1),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("help.psych2")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_10, _toDisplayString(_ctx.$t("menu.social")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "auto",
                      alt: "social-circle-category",
                      style: {"height":"192px","width":"192px"},
                      src: '/../assets/social-circle.png'
                    }, null, 8, _hoisted_11)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("help.social")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_13, _toDisplayString(_ctx.$t("menu.spirit")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "auto",
                      alt: "spirit-circle-category",
                      style: {"height":"192px","width":"192px"},
                      src: '/../assets/spiritual-circle.png'
                    }, null, 8, _hoisted_14)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("help.spirit1")), 1),
                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("help.spirit2")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "pa-4" })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}